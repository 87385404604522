import { Constants } from "../../constants/Contants";
import request from "../../utils/request";
import { toast } from "react-toastify";

export const createFavotireList = async (data) => {
  try {
    const res = await request.post(Constants.create_favorite_list, data);
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const updateFavoriteList = async (_id, favList) => {
  try {
    const res = await request.post(Constants.update_favorite_list, {
      _id,
      favList,
    });
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const getFavoriteLists = async (userData) => {
  try {
    const res = await request.get(`${Constants.get_favorite_list}/${userData.clientId}`);
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const addFavoriteMaterials = async (favLists, userData, materials) => {
  try {
    const res = await request.post(Constants.add_favorite_materials, {
      favLists,
      clientId: userData.clientId,
      user: userData._id,
      materials
    });
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};
