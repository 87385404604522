import React, { useEffect, useState } from 'react';
import useFortnoxAPI from "../../../hooks/useFortnoxAPI";

const FortnoxIntegration = ({ clientId }) => {
    const {
        isAPIActive,
        authURL,
        getAuthURL,
        handleOAuthCallback,
        testAPIConnection,
        diagnosticMessages,
    } = useFortnoxAPI(clientId);

    const [additionalAccessTokenIdInProgress, setAdditionalAccessTokenIdInProgress] = useState(null);
    const [newAdditionalFortnoxConnectionFlag, setNewAdditionalFortnoxConnectionFlag] = useState(false);

    useEffect(() => {
        // Optionally trigger auth URL retrieval or API tests when component mounts
        if (!isAPIActive) {
            getAuthURL();
        }
    }, [isAPIActive, getAuthURL]);

    const handleTestConnection = () => {
        testAPIConnection();
    };

    const setupOAuth2AuthenticationWithFortnox = (createServiceAccount) => {
        const queryParams = new URLSearchParams({
            createServiceAccount: createServiceAccount,
        });

        fetch(`/api/getFortnoxOAuth2AuthURL?${queryParams.toString()}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })
            .then(response => response.json())
            .then(resultFromServer => {
                const newWindow = window.open(resultFromServer.url, 'Fortnox integration', 'height=800,width=1000');
                if (window.focus) {
                    newWindow.focus();
                }
            })
            .catch(error => {
                console.error('Error fetching the Fortnox OAuth2 URL:', error);
            });
    };


    const addNewFortnoxOAuthConnection = () => {
        const newId = Math.random().toString(36).substr(2, 9);
        setAdditionalAccessTokenIdInProgress(newId);
        setNewAdditionalFortnoxConnectionFlag(true);

        const queryParams = new URLSearchParams({
            additionalAccessTokenId: newId,
        });

        fetch(`/api/getFortnoxOAuth2AuthURL?${queryParams.toString()}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })
            .then(response => response.json())
            .then(resultFromServer => {
                const newWindow = window.open(resultFromServer.url, 'Fortnox integration', 'height=800,width=1000');
                if (window.focus) {
                    newWindow.focus();
                }
            })
            .catch(error => {
                console.error('Error fetching the Fortnox OAuth2 URL:', error);
            });
    };


    const fortnoxOAuth2APIActivated = () => {
        if (newAdditionalFortnoxConnectionFlag) {
            const connectionName = prompt('Please specify a name for the connection:');
            if (connectionName) {
                // Save the new additional connection with the specified name
                console.log(`New connection name: ${connectionName}, ID: ${additionalAccessTokenIdInProgress}`);
                // Here you would likely update the state or send this data to the server
            }
        } else {
            // Standard connection activated
            console.log('Fortnox API standard connection activated.');
            // Here you would update your state to reflect that the API is now active
        }
    };

    return (
        <div>
            <h1>Fortnox Integration</h1>
            {isAPIActive ? (
                <p>API is active!</p>
            ) : (
                <p>API is not active. <a href={authURL}>Authorize Fortnox</a></p>
            )}
            <button onClick={handleTestConnection}>Test Connection</button>
            <button onClick={() => setupOAuth2AuthenticationWithFortnox(false)}>Authorize with User Account</button>
            <button onClick={() => setupOAuth2AuthenticationWithFortnox(true)}>Authorize with Service Account</button>
            <button onClick={addNewFortnoxOAuthConnection}>Add New Fortnox Connection</button>
            <div>
                {diagnosticMessages.map((message, index) => (
                    <p key={index} dangerouslySetInnerHTML={{ __html: message }} />
                ))}
            </div>
        </div>
    );
};

export default FortnoxIntegration;
