import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchInventoryDetails, addMaterial, deleteMaterial } from "../../redux/slices/inventorySlice";
import "../../assets/scss/inventory.scss"; // Import SCSS file for custom styling

const InventoryDetail = () => {
    const { id } = useParams(); // ✅ Get inventory ID from URL
    const dispatch = useDispatch();
    const { inventory, loading } = useSelector((state) => state.inventory);

    const [materialName, setMaterialName] = useState("");
    const [materialQuantity, setMaterialQuantity] = useState("");

    useEffect(() => {
        dispatch(fetchInventoryDetails(id));
    }, [dispatch, id]);

    const handleAddMaterial = async () => {
        if (!materialName || !materialQuantity) {
            alert("Fyll i alla fält!");
            return;
        }

        const material = { name: materialName, quantity: materialQuantity };

        await dispatch(addMaterial(id, material));

        setMaterialName("");
        setMaterialQuantity("");

        dispatch(fetchInventoryDetails(id)); // ✅ Refresh inventory
    };

    const handleDeleteMaterial = (index) => {
        dispatch(deleteMaterial(id, index));
        dispatch(fetchInventoryDetails(id)); // ✅ Ensure update
    };

    if (loading) return <p>Laddar...</p>;
    if (!inventory) return <p>Ingen data hittades.</p>;

    return (
        <div className="inventory-container">
            <h2>{inventory.name}</h2>

            {/* Add Material Form */}
            <div className="material-form">
                <input
                    type="text"
                    placeholder="Materialnamn"
                    value={materialName}
                    onChange={(e) => setMaterialName(e.target.value)}
                />
                <input
                    type="number"
                    placeholder="Antal"
                    value={materialQuantity}
                    onChange={(e) => setMaterialQuantity(e.target.value)}
                />
                <button className="add-material-btn" onClick={handleAddMaterial}>
                    ➕ Lägg till Material
                </button>
            </div>

            {/* Material List Table */}
            <table className="material-table">
                <thead>
                <tr>
                    <th>Materialnamn</th>
                    <th>Antal</th>
                    <th>Åtgärd</th>
                </tr>
                </thead>
                <tbody>
                {inventory?.materials?.map((mat, index) => (
                    <tr key={index}>
                        <td>{mat.name}</td>
                        <td>{mat.quantity} {mat.unit}</td>
                        <td>
                            <button className="delete-btn" onClick={() => handleDeleteMaterial(index)}>
                                ❌ Ta bort
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default InventoryDetail;
