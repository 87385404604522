import { useState, useEffect } from 'react';
import axios from 'axios';

const useFortnoxAPI = (clientId) => {
    const [isAPIActive, setIsAPIActive] = useState(false);
    const [authURL, setAuthURL] = useState('');
    const [diagnosticMessages, setDiagnosticMessages] = useState([]);

    useEffect(() => {
        // Check if API is active
        const checkAPIStatus = async () => {
            try {
                const response = await axios.post('/api/fortnox/is-api-active', { clientId });
                setIsAPIActive(response.data.isActive);
            } catch (err) {
                console.error('Error checking API status:', err);
            }
        };

        if (clientId) {
            checkAPIStatus();
        }
    }, [clientId]); // Ensure useEffect is triggered when clientId changes

    const getAuthURL = async (createServiceAccount = false) => {
        try {
            const response = await axios.get('/api/fortnox/get-auth-url', {
                params: { createServiceAccount }
            });
            setAuthURL(response.data.url);
        } catch (err) {
            console.error('Error getting auth URL:', err);
        }
    };

    const handleOAuthCallback = async (code, state, error) => {
        if (error) {
            console.error('Error during OAuth callback:', error);
            return;
        }

        try {
            const response = await axios.post('/api/fortnox/oauth2callback', { code, state });
            console.log(response.data.message);
        } catch (err) {
            console.error('Error handling OAuth callback:', err);
        }
    };

    const testAPIConnection = async (additionalAccessToken = null) => {
        try {
            const response = await axios.post('/api/fortnox/test-api', { additionalAccessToken });
            setDiagnosticMessages(response.data.accessMessages);
        } catch (err) {
            console.error('Error testing API connection:', err);
        }
    };

    return {
        isAPIActive,
        authURL,
        getAuthURL,
        handleOAuthCallback,
        testAPIConnection,
        diagnosticMessages,
    };
};

export default useFortnoxAPI;
