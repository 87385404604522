import React, { useEffect, useState } from "react";
import axios from "axios";
import { organizationList, updateOrganization } from "../../../redux/actions/organizationAction";
import { Button } from "react-bootstrap";
import CircleX from "../../../assets/images/circle-x.svg";
import { ToggleButton, ButtonGroup } from "react-bootstrap";
import { toast } from "react-toastify";

const GrossisterTab = () => {
  const [wholesalers, setWholesalers] = useState([]);
  const [customerNumbers, setCustomerNumbers] = useState({});

  // useEffect(() => {
  //     axios.get("/api/wholesalers").then((res) => {
  //         setWholesalers(res.data);
  //         const initialNumbers = {};
  //         res.data.forEach((w) => (initialNumbers[w._id] = w.customerNumber || ""));
  //         setCustomerNumbers(initialNumbers);
  //     });
  // }, []);

  const fetchOrganizationList = async () => {
    const data = await organizationList();
    setWholesalers(data?.data);
  };

  useEffect(() => {
    fetchOrganizationList();
  }, []);

  const saveCustomerNumber = (id) => {
    axios.put(`/api/wholesalers/${id}/update-customer-number`, {
      customerNumber: customerNumbers[id],
    });
  };

  const handleSave = async(org) => {
     const data = await updateOrganization(org._id, {wholeSellerFileId: customerNumbers[org._id]})
     if(data?.status) {
      toast.success("Saved successfully.")
     }
  }

  return (
    <div>
      <h2>Grossister</h2>
      {/* {wholesalers.map((w) => (
        <div key={w._id}>
          <span>{w.name}</span>
          <input
            value={customerNumbers[w._id]}
            onChange={(e) =>
              setCustomerNumbers({
                ...customerNumbers,
                [w._id]: e.target.value,
              })
            }
          />
          <button onClick={() => saveCustomerNumber(w._id)}>Spara</button>
        </div>
      ))} */}
      {wholesalers?.map((w) => (
        <ul>
          <li className="d-flex gap-2" 
          style={{ borderBottom: "1px solid #ddd", marginBottom: "5px" }}>
            <>
            <span style={{minWidth: "130px", marginBottom: "10px"}}>{w.companyName}</span>
            <input
              value={customerNumbers[w._id]}
              onChange={(e) =>
                setCustomerNumbers({
                  ...customerNumbers,
                  [w._id]: e.target.value,
                })
              }
            />          
            <Button
                className=""
                onClick={() => handleSave(w)}
              >
                {("save")}
              </Button>             
                <img height={30} src={CircleX} alt="pdf" />
            </>
          </li>
        </ul>
      ))}
    </div>
  );
};

export default GrossisterTab;
