export const Constants = {
  //baseURL: "http://localhost:8080/",
  // baseURL: "http://18.130.229.159:8080/",
  baseURL: "https://0tid.webbverket.se/",

  admin_list_api_url: "/users/admin-list",
  admin_update_api_url: "/users/update-admin",

  signup_api_url: "/auth/signup",
  signin_api_url: "auth/login",
  staff_signin_api_url: "auth/staff-login",

  get_price_lists_api_url: "/pricelist/get-price-lists",

  create_customer_api_url: "/customer/create-customer",
  update_customer_api_url: "/customer/update-customer",
  customer_list_api_url: "/customer/customer-list",
  get_customer_by_id: "/customer/get-customer-by-id",

  create_staff_api_url: "/staff/create-staff",
  update_staff_api_url: "/staff/update-staff",
  staff_list_api_url: "/staff/staff-list",
  login_history_api_url: "/staff/login-history-list",

  create_project_api_url: "/project/create-project",
  project_list_api_utl: "/project/project-list",
  update_project_api_url: "/project/update-project",

  create_facility_api_url: "/facility/create-facility",
  facility_list_api_url: "/facility/facility-list",
  update_facility_api_url: "/facility/update-facility",

  create_workorder_api_url: "/workorder/create-workorder",
  workorder_list_api_url: "/workorder/workorder-list",
  update_workorder_api_url: "workorder/update-workorder",
  create_workorder_attributes_api_url: "workorder/create-workorder-attributes",
  update_workorder_attributes_api_url: "workorder/update-workorder-attributes",
  delete_workorder_attributes_api_url: "workorder/delete-workorder-attributes",
  upload_file_api_url: "/workorder/uploads",
  get_uploaded_file: "/workorder/get-uploaded-file",
  get_allworkorder_timereports: "/workorder/workorder-timereports",

  create_article_api_url: "/article/create-article",
  article_list_api_url: "/article/article-list",
  update_article_api_url: "/article/update-article",
  delete_article_api_url: "/article/article-delete",

  create_fixedArticle_api_url: "/fixedArticle/create-fixedArticle",
  fixedArticle_list_api_url: "/fixedArticle/fixedArticle-list",
  update_FixedArticle_api_url: "/fixedArticle/update-fixedArticle",

  create_resource_api_url: "/resource/create-resource",
  resources_list_api_url: "/resource/resources-list",
  update_resource_api_url: "/resource/update-resource",

  create_self_control_api_url: "selfControl/create-self-control",
  self_control_list_api_url: "/selfControl/self-control-list",
  update_self_control_api_url: "selfControl/update-self-control",

  create_self_control_lists_api_url:
    "/selfControlList/create-self-control-list-item",
  self_control_lists_api_url: "/selfControlList/self-control-item-list",
  update_self_control_lists_api_url:
    "/selfControlList/update-self-control-list",
  delete_selcontrol_point_api_url: "/selfControlList/delete-selfcontrol-point",
  update_selcontrollist_point_api_url:
    "/selfControlList/update-selfcontrollist-point",

  create_machine_api_url: "/machine/create-machine",
  machines_list_api_url: "/machine/machines-list",
  update_machine_api_url: "/machine/update-machine",

  create_stationsort_api_url: "/stationsort/create-stationsort",
  stationsort_list_api_url: "/stationsort/stationsorts-list",
  update_stationsort_api_url: "/stationsort/update-stationsort",

  create_priority_api_url: "/priority/create-priority",
  priority_list_api_url: "/priority/priority-list",
  update_priority_api_url: "/priority/update-priority",

  create_organization_api_url: "/organization/create-organization",
  organization_list_api_url: "/organization/organization-list",
  update_organiation_api_url: "/organization/update-organization",

  create_invoice_api_url: "/invoice/create-invoice",
  invoice_list_api_url: "/invoice/invoice-list",
  update_invoice_api_url: "/invoice/update-invoice",
  delete_invoice_api_url: "/invoice/delete-invoice",

  create_favorite_list: "/favoriteList/create-favoriteList",
  update_favorite_list: "/favoriteList/update-favoriteList",
  get_favorite_list: "/favoriteList/favorite-lists",
  add_favorite_materials: "/favoriteList/add-favorite-materials",

  file_upload_generic_api_url: "/upload/file",
  whole_seller_files: "/upload/wholesellerFiles",
  whole_seller_files_data : "/upload/getFilesData",

  create_workrole_api_url: "/workrole/create",
  workrole_list_api_url: "/workrole/list",
  update_workrole_api_url: "/workrole/update",

  get_allStaff_workSchedules: "/workSchedule/allWork-schedules"
};

//--------------------------------DROP-DOWN-CONSTANT-VALUES-------------------------------------------

export const PersonalGroupDropDownValues = [
  { name: "No", value: "no" },
  { name: "Computer Technician", value: "computerTechnician" },
  { name: "Eletriker", value: "eletriker" },
  { name: "Excavator", value: "excavator" },
  { name: "Apprentice", value: "apprentice" },
  { name: "Magnus", value: "magnus" },
  { name: "Technician", value: "technician" },
];
export const CompetenceDropDownValues = [
  { name: "Personal", value: "personal" },
  { name: "Administrator", value: "administrator" },
  { name: "Serice Staff", value: "serviceStaff" },
  { name: "Serice Staff +", value: "serviceStaffPlus" },
];
export const TypeOfEmploymentDropDownValues = [
  { name: "Permanent Employment", value: "permanentEmployment" },
  { name: "Necessary Employment", value: "necessaryEmployment" },
  { name: "F Tax", value: "fTax" },
];
export const LoneFormDropDownValues = [
  { name: "Monthly Salary", value: "monthlySalary" },
  { name: "Hourly Salary", value: "hourlySalary" },
  { name: "No Salary", value: "noSalary" },
];
export const CollectiveAgreementDropDownValues = [
  { name: "No Agreement", value: "noAgreement" },
  { name: "The Building Agreement", value: "buildingAgreement" },
  { name: "Own Agreement", value: "ownAgreement" },
  { name: "Electrician - Installation", value: "electricianInstallatiom" },
  { name: "Electrician - Power Plant", value: "electricianPowerPlant" },
  { name: "Electrician - Service", value: "electricianService" },
  { name: "The Forest Agreement", value: "forestAgreement" },
  {
    name: "The Technology Agreement - SIF/CF/Ledarna",
    value: "technologyAgreement",
  },
  { name: "The Contract Of Carriage", value: "contractOfCarriage" },
  { name: "VVS - Installation", value: "vvsInstallation" },
];
export const LanguageDropDownValues = [
  { name: "Swedish", value: "swedish" },
  { name: "English", value: "english" },
];

export const PRIORITIES = [
  { label: "Normal priority", value: "Normal priority" },
  { label: "High priority", value: "High priority" },
  { label: "Low priority", value: "Low priority" },
];

export const STATUSES = [
  { label: "Ongoing", value: "Ongoing" },
  { label: "Completed", value: "Completed" },
  { label: "Invoiced", value: "Invoiced" },
  { label: "Shredded", value: "Shredded" },
  { label: "Warranty job", value: "Warranty job" },
  { label: "Warranty job completed", value: "Warranty job completed" },
  
  {
    label: "Completed, ready to invoice",
    value: "Completed, ready to invoice",
  },
  { label: "Remaining questions", value: "Remaining questions" },
  // { label: "New offer", Value: "New offer" },
  // { label: "Approved Offer", value: "Approved Offer" },
  // { label: "Denied Offer", value: "Denied Offer" },
  // { label: "Offer", value: "Offer" },
];

export const OFFER_STATUSES = [
  {label: "New offer", value: "New offer"},
  {label: "Approved offer", value: "Approved offer"},
  {label: "Denied offer", value: "Denied offer"}
]

export const PRICETYPES = [
  { label: "Current", value: "Current" },
  { label: "Fixed price", value: "Fixed price" },
];

export const DEBIT_FORM = [
  { label: "Per hour", value: "Per hour" },
  { label: "Per working day", value: "Per workingday" },
  { label: "Per calendar day", value: "Per calendar day" },
];

export const RESOURCE_TYPE = [
  { label: "Dehumidifier", value: "dehumidifier" },
  { label: "Machine", value: "machine" },
];

export const TIME_RECORDING = [
  { label: "Manually", value: "manually" },
  { label: "Timestamping", value: "timestamp" },
];

export const WHOLESALE_LIST = [
  { label: "Ahlsell", value: "Ahlsell" },
  { label: "Solar", value: "Solar" },
  { label: "Sell", value: "Sell" },
  { label: "Free text article", value: "Free text article" },
  { label: "Underestreprened", value: "Underestreprened" },
];

export const priceListOptions = [
  { name: "Price list 25%", value: "25%" },
  { name: "Price list 30%", value: "30%" },
  { name: "Price list 40%", value: "40%" },
  { name: "Price list 50%", value: "50%" },
];

export const wholesaleLockOptions = [
  { name: "Nothing", value: "nothing" },
  { name: "Move", value: "move" },
  { name: "Good gross", value: "good gross" },
  { name: "Rexel", value: "rexel" },
];

export const companyTypes = [
  { name: "Compant with tax", value: "Company with tax" },
  { name: "Company without tax", value: "Company without tax" },
];

export const deviationTypes = [
  { name: "Disease", value: "Disease" },
  { name: "Care of childern", value: "Care of childern" },
  { name: "Parentasl leave", value: "Parental leave" },
  { name: "Other absences", value: "Other absences" },
  { name: "Complete", value: "Complete" },
  { name: "Father's day", value: "Father's day" },
  { name: "Semester", value: "Semester" },
];

export const HOURS = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];
export const OVERTIME_HOURS = ["00", "01", "02"];
export const MINUTES = ["00", "15", "30", "45"];

//-------------------------TOOL TIPS------------------------------
