import React, { useEffect, useState } from "react";
import { Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { selfControlLists } from "../../../redux/actions/selfControlListActions";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#f5f5f5",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    padding: 10,
  },
  section: {
    flexDirection: "row",
  },
  table: {
    display: "table",
    width: "100%",
    borderWidth: 0,
    alignSelf: "flex-start",
    marginTop: 25,
  },
  tableRow: {
    flexDirection: "row",
    fontWeight: "normal",
  },
  tableRowHead: {
    flexDirection: "row",
    textTransform: "uppercase",
    color: "#000",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    fontWeight: 900,
  },
  tableCol: {
    width: "33.33%",
    padding: 4,
  },
  tableCell: {
    fontSize: 10,
    textAlign: "center",
  },
  tableStripped: {
    backgroundColor: "#eeeeee",
  },
  largePage: {
    size: "A2",
    flexDirection: "column",
    backgroundColor: "#f5f5f5",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    padding: 10,
  },
  tableRowLast: {
    borderTopColor: "#000",
    borderTopWidth: 1,
    fontWeight: 900,
  },
  image: {
    width: 100,
    marginTop: 10,
  },
  imgCol: {
    width: "35%",
    textAlign: "center",
  },
  heading: {
    width: "65%",
    marginTop: 20,
  },
  headerSection: {
    flexDirection: "row",
    marginTop: 15,
  },
  headerCol: {
    width: "33%",
    textAlign: "left",
  }
});


const CombinePdf = ({ workorder }) => {
  const { timeReports,material,selfControlList, document } = workorder;
  const [selfControls,setSelfControls]=useState([]);
  const [SelectedSelfControlsList,setSelectedSelfControlsList]=useState([]);
  const { t } = useTranslation();
  const getSelfControlList = async () => {
    const list = await selfControlLists();
    setSelfControls(list?.data);
     const selfControl = list?.data?.find((s) => s._id === selfControlList);
   
     setSelectedSelfControlsList(selfControl.points);
  };
  useEffect(()=>{
    getSelfControlList();
  },[])
  return (
    <Document>
      <Page size="A2" style={styles.largePage}>
        <View style={styles.section}>
          <View style={styles.imgCol}>
            <Image style={styles.image} src="https://react-pdf.org/images/logo.png" />
          </View>
          <View style={styles.heading}>
            <Text style={{ fontSize: 25, fontWeight: "bold", }}>20190427 elcentral inligt offert</Text>
            <View style={styles.headerSection}>
              <View style={styles.headerCol}>
                <Text style={{ fontSize: 15, fontWeight: "bold" }}>{t("customer")}</Text>
                <Text style={{ fontSize: 13, fontWeight: "normal" }}>20203 Stena Kobbe åäö</Text>
                {/* <Text style={{ fontSize: 13, fontWeight: "normal" }}>0739183396</Text> */}
              </View>
              <View style={styles.headerCol}>
                <Text style={{ fontSize: 15, fontWeight: "bold" }}>{t("contact_person")}</Text>
                <Text style={{ fontSize: 13, fontWeight: "normal" }}>roswell niklasson</Text>
                {/* <Text style={{ fontSize: 13, fontWeight: "normal" }}>0739183396</Text> */}
              </View>
              <View style={styles.headerCol}>
                <Text style={{ fontSize: 15, fontWeight: "bold" }}>{t("address")}</Text>
                <Text style={{ fontSize: 13, fontWeight: "normal" }}>Björkhemsvägen 26</Text>
                {/* <Text style={{ fontSize: 13, fontWeight: "normal" }}>29154 Kristianstad</Text> */}
              </View>
            </View>
          </View>
        </View>
        { }
        <View style={styles.table}>
          <View style={(styles.tableRowHead)}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>START</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>STOP </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Staff</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Day </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>OB1</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>OB2</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>OB3</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>OB4</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>ÖT1</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>ÖT2</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>ÖT3</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>ÖT4</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Sum</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>KM</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>RAST</Text>
            </View>
          </View>
          {timeReports?.map((t) => (
            <View style={[styles.tableRow, styles.tableStripped]}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  <Text>{moment(t.start).format("DD-MM-YYYY")}</Text>
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  <Text>{moment(t.stop).format("DD-MM-YYYY")}</Text>
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{`${t.user.first_name} ${t.user.last_name}`}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{t.routineHours}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{t.overtime}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{t.hoursOnWeekend}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{t.totalHours}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
              </View>
            </View>
          ))}
        
        </View>
      </Page>
      <Page size="A2" style={styles.largePage}>
        <View style={styles.section}>
          <View style={styles.imgCol}>
            <Image style={styles.image} src="https://react-pdf.org/images/logo.png" />
          </View>
          <View style={styles.heading}>
            <View style={styles.headerSection}>
              <View style={styles.headerCol}>
                <Text style={{ fontSize: 15, fontWeight: "bold" }}>{t("customer")}</Text>
                <Text style={{ fontSize: 13, fontWeight: "normal" }}>20203 Stena Kobbe åäö</Text>
                {/* <Text style={{ fontSize: 13, fontWeight: "normal" }}>0739183396</Text> */}
              </View>
              <View style={styles.headerCol}>
                <Text style={{ fontSize: 15, fontWeight: "bold" }}>{t("contact_person")}</Text>
                <Text style={{ fontSize: 13, fontWeight: "normal" }}>roswell niklasson</Text>
                {/* <Text style={{ fontSize: 13, fontWeight: "normal" }}>0739183396</Text> */}
              </View>
              <View style={styles.headerCol}>
                <Text style={{ fontSize: 15, fontWeight: "bold" }}>{t("address")}</Text>
                <Text style={{ fontSize: 13, fontWeight: "normal" }}>Björkhemsvägen 26</Text>
                {/* <Text style={{ fontSize: 13, fontWeight: "normal" }}>29154 Kristianstad</Text> */}
              </View>
            </View>
          </View>
        </View>
        { }
        <View style={styles.table}>
        <View style={styles.tableRowHead}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("wholesale")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("article_number")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("article_name")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("unit")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("quantity")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("purchase_price")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("selling_price")}</Text>
            </View>
           
          </View>
          {material?.map((item, index) => (
           <View key={index} style={styles.headerSection}>
           <View style={styles.tableCol}>
             <Text style={styles.tableCell}>
               <Text>{item.wholesale}</Text>
             </Text>
           </View>
           <View style={styles.tableCol}>
             <Text style={styles.tableCell}>
               <Text>{item.number}</Text>
             </Text>
           </View>
           <View style={styles.tableCol}>
             <Text style={styles.tableCell}>
               <Text>{item.name}</Text>
             </Text>
           </View>
           <View style={styles.tableCol}>
             <Text style={styles.tableCell}>
               <Text>{item.unit}</Text>
             </Text>
           </View>
           <View style={styles.tableCol}>
             <Text style={styles.tableCell}>
               <Text>{item.quantity}</Text>
             </Text>
           </View>
           <View style={styles.tableCol}>
             <Text style={styles.tableCell}>
               <Text>{item.purchasePrice}</Text>
             </Text>
           </View>
           <View style={styles.tableCol}>
             <Text style={styles.tableCell}>
               <Text>{item.sellingPrice}</Text>
             </Text>
           </View>
         </View>
          ))}
        </View>
      </Page>
      <Page size="A2" style={styles.largePage}>
        <View style={styles.section}>
          <View style={styles.imgCol}>
            <Image style={styles.image} src="https://react-pdf.org/images/logo.png" />
          </View>
          <View style={styles.heading}>
            <Text style={{ fontSize: 25, fontWeight: "bold", }}>20190427 elcentral inligt offert</Text>
            <View style={styles.headerSection}>
              <View style={styles.headerCol}>
                <Text style={{ fontSize: 15, fontWeight: "bold" }}>{t("customer")}</Text>
                <Text style={{ fontSize: 13, fontWeight: "normal" }}>20203 Stena Kobbe åäö</Text>
                {/* <Text style={{ fontSize: 13, fontWeight: "normal" }}>0739183396</Text> */}
              </View>
              <View style={styles.headerCol}>
                <Text style={{ fontSize: 15, fontWeight: "bold" }}>{t("contact_person")}</Text>
                <Text style={{ fontSize: 13, fontWeight: "normal" }}>roswell niklasson</Text>
                {/* <Text style={{ fontSize: 13, fontWeight: "normal" }}>0739183396</Text> */}
              </View>
              <View style={styles.headerCol}>
                <Text style={{ fontSize: 15, fontWeight: "bold" }}>{t("address")}</Text>
                <Text style={{ fontSize: 13, fontWeight: "normal" }}>Björkhemsvägen 26</Text>
                {/* <Text style={{ fontSize: 13, fontWeight: "normal" }}>29154 Kristianstad</Text> */}
              </View>
            </View>
          </View>
        </View>
        <View style={styles.table}>
          <View style={(styles.tableRowHead)}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("number")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("name")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("approved_by")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("time_of_approval")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("notes")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("attachment")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("approved")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("failed")}</Text>
            </View>
          </View>
          {SelectedSelfControlsList.map((e, index) => (
            <View style={[styles.tableRow, index % 2 === 0 && styles.tableStripped]} key={index}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  <Text>{index + 1}</Text>
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  <Text>{e.point}</Text>
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{e.approvedBy}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{moment(e.time).format("YYYY-MM-DD HH:MM") || ""}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{e.notes}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>xyz</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{e.workordersData.find(e => e.workOrder === workorder._id)?.isApproved ? "Approved" : ""}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{e.workordersData.find(e => e.workOrder === workorder._id)?.isFailed ? "Failed" : ""}</Text>
              </View>
            </View>
          ))}
        </View>
      </Page>

      <Page size="A2" style={styles.largePage}>
        <View style={styles.section}>
          <View style={styles.imgCol}>
            <Image style={styles.image} src="https://react-pdf.org/images/logo.png" />
          </View>
          <View style={styles.heading}>
            <Text style={{ fontSize: 25, fontWeight: "bold", }}>20190427 elcentral inligt offert</Text>
            <View style={styles.headerSection}>
              <View style={styles.headerCol}>
                <Text style={{ fontSize: 15, fontWeight: "bold" }}>Kund</Text>
                <Text style={{ fontSize: 13, fontWeight: "normal" }}>20203 Stena Kobbe åäö</Text>
                <Text style={{ fontSize: 13, fontWeight: "normal" }}>0739183396</Text>
              </View>
              <View style={styles.headerCol}>
                <Text style={{ fontSize: 15, fontWeight: "bold" }}>Kontaktperson</Text>
                <Text style={{ fontSize: 13, fontWeight: "normal" }}>roswell niklasson</Text>
                <Text style={{ fontSize: 13, fontWeight: "normal" }}>0739183396</Text>
              </View>
              <View style={styles.headerCol}>
                <Text style={{ fontSize: 15, fontWeight: "bold" }}>Arbetsplatsadress</Text>
                <Text style={{ fontSize: 13, fontWeight: "normal" }}>Björkhemsvägen 26</Text>
                <Text style={{ fontSize: 13, fontWeight: "normal" }}>29154 Kristianstad</Text>
              </View>
            </View>
          </View>
        </View>
        { }
        <View style={styles.table}>
          <View style={(styles.tableRowHead)}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Name</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Uploaded By</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Date</Text>
            </View>
            
          </View>
          {document.map((d) => (
            <View style={[styles.tableRow, styles.tableStripped]}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  <Text>{d.docName}</Text>
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  <Text>{d.uploadedBy}</Text>
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{moment(d.date).format("DD-MM-YYYY")}</Text>
              </View>
            </View>
          ))}
                 </View>
      </Page>
    </Document>
  )
};

export default CombinePdf;
