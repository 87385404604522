import React, {useEffect, useState} from "react";
import { getWholeSellerFiles } from "../redux/actions/uploadActions";

const FolderList = ({ folders }) => {
  if (!folders || folders.length === 0) {
    return null;
  }

  return (
    <ul style={{ listStyleType: "none", paddingLeft: "20px" }}>
      {folders.map((folder) => (
        <FolderItem key={folder.path} folder={folder} />
      ))}
    </ul>
  );
};

const FolderItem = ({ folder }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleFolder = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <li>
      <div
        onClick={toggleFolder}
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          fontWeight: "bold",
        }}
      >
        <span style={{ marginRight: "5px" }}>
          {isOpen ? "📂" : "📁"}
        </span>
        {folder.name}
      </div>
      {isOpen && folder.subfolders && folder.subfolders.length > 0 && (
        <FolderList folders={folder.subfolders} />
      )}
    </li>
  );
};

const EDeliveryNotesComponent = () => {

  const [folders, setFolder] = useState([])

  useEffect(() => {
    const getFiles = async() => {
      const data = await getWholeSellerFiles()
      setFolder(data?.data)
    }
    getFiles()
  },[])

  return (
  <div className="d-flex">
     {/* <FolderList folders={folders} /> */}
  </div>);
};

export default EDeliveryNotesComponent;