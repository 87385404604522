import React, { useState } from "react";
import { FaStickyNote } from "react-icons/fa"; // Icon for modal title
import { Modal, Button } from "react-bootstrap";

const NotesDialog = ({ row, title }) => {
    const [showDialog, setShowDialog] = useState(false);

    const handleOpenDialog = () => setShowDialog(true);
    const handleCloseDialog = () => setShowDialog(false);

    if (!row.note) {
        return null; // If no note exists, don't render anything
    }

    return (
        <div className="note-container">
            <FaStickyNote className="note-icon" onClick={handleOpenDialog} style={{ color: "blue" }} />
            <Modal show={showDialog} onHide={handleCloseDialog} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FaStickyNote className="modal-title-icon" /> {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{row.note}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDialog}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default NotesDialog;
