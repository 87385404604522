import React from 'react';
import { Button } from "react-bootstrap";
import { useIsExportInvoiceVisible } from '../../CommonDisableFunction';
import { useSelector } from 'react-redux';
const CommonButton = ({ handleSubmit, setIsPdfTypeModalVisible, exportInvoice, isOffer }) => {

    const isExportInvoiceVisible = useIsExportInvoiceVisible();
    return (
      <div
        className="w-100 p-3 work-order-bottom-btn flex justify-content-start gap-3 position-fixed bottom-0 bg-light"
        style={{ zIndex: "1" }}>
        <div className="d-flex gap-3">
          <Button className="simple-btn mx-auto" onClick={handleSubmit}>
            Save
            <i className="fa fa-save m-2 fs-5"></i>
          </Button>
          <Button
            className="simple-btn mx-auto"
            onClick={() => {
              setIsPdfTypeModalVisible(true);
            }}>
            PDF View
          </Button>
          {isExportInvoiceVisible && !isOffer &&  (
            <Button className="simple-btn mx-auto" onClick={exportInvoice}>
              Export Invoice
              <i className="fa fa-save m-2 fs-5"></i>
            </Button>
          )}
        </div>
      </div>
    );
};

export default CommonButton;