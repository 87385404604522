import { useState } from "react";
import { useDispatch } from "react-redux";
import { createInventory } from "../../redux/slices/inventorySlice";

const CreateWarehouse = () => {
    const [name, setName] = useState("");
    const dispatch = useDispatch();

    const handleCreate = () => {
        if (!name.trim()) {
            alert("Warehouse name cannot be empty!"); // ✅ User feedback
            return;
        }
        dispatch(createInventory({ name: name.trim() })); // ✅ Trim name before sending
        setName(""); // ✅ Clear input after creation
    };

    return (
        <div>
            <input
                type="text"
                placeholder="Enter warehouse name"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <button onClick={handleCreate}>Create Warehouse</button>
        </div>
    );
};

export default CreateWarehouse;
