import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";

function ArticleMegaMenu({
  priceListDataState,
  handleMaterialItemNumber,
  favoritsLists,
  handleCheckboxChange,
  handleAddFavMaterials,
  handleFavMaterialChange,
  favMaterials,
  checkedFavLists,
  showOnlyList,
  dataRender,
  hideWindow,
  materialWholesale,
}) {
  const { t } = useTranslation();
  return (
    <div className="articleMegaMenu">
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th className="col-w-150px">{t("article_number")}</th>
              <th className="col-w-450px">{t("article_name")}</th>
              <th className="col-w-120px">{t("purcahse_price")}</th>
              <th className="col-w-120px">{t("selling_price")}</th>
              <th>
                <i className="fa fa-star-o"></i>
              </th>
            </tr>
          </thead>
          <tbody>
            <React.Fragment>
              {priceListDataState?.length ? (
                priceListDataState?.map((e, index) => (
                  // Main row
                  <tr key={index}>
                    <td
                      onClick={() =>
                        handleMaterialItemNumber({
                          label: e.number,
                          value: e.number,
                        })
                      }
                    >
                      {e.number}
                    </td>
                    <td
                      onClick={() =>
                        handleMaterialItemNumber({
                          label: e.name,
                          value: e.name,
                        })
                      }
                    >
                      {e.name}
                    </td>
                    <td>
                      {+e?.grossPrice?.replace(/,/g, "") -
                        (+e?.grossPrice?.replace(/,/g, "") / 100) * 10}
                    </td>
                    <td>{e.grossPrice}</td>
                    <td>
                      {materialWholesale?.toLowerCase()=='ahlsell' || materialWholesale?.toLowerCase()=='solar' || materialWholesale?.toLowerCase()=='sell' ||   materialWholesale?.toLowerCase()=='wholeseller'  ? (
                         <i
                         className={`fa ${
                           favMaterials?.includes(e._id)
                             ? "fa-star"
                             : "fa-star-o"
                         }`}
                         onClick={() => handleFavMaterialChange(e._id)}
                       />
                       
                      ) : (
                        <i id="fa-star" className="fa fa-star" onClick={() => handleFavMaterialChange(e._id)}/>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  No Result Found
                </div>
              )}
            </React.Fragment>
          </tbody>
        </table>
      </div>
      {!showOnlyList && (
        <div className="favorite mb-5">
          <div className="d-flex justify-content-between">
            <p>Favorite lists</p>
            <div>
              <i
                className="fa fa-times-circle fa-lg m-2 cursor-pointer"
                onClick={hideWindow}
              ></i>
            </div>
          </div>
          <div className="list">
            <ul>
              <li>
                {favoritsLists?.map((list, index) => (
                  <Form.Check
                    type="checkbox"
                    id={index}
                    label={list.name}
                    checked={checkedFavLists?.includes(list?._id)}
                    onChange={() => handleCheckboxChange(list?._id)}
                  />
                ))}
              </li>
            </ul>
            <button onClick={(e) => handleAddFavMaterials(e)}>
              Add in list
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ArticleMegaMenu;
