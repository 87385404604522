import Joi from "joi";

const handleMassage = (name, messgae, type) => {
  return {
    "string.base": `${name} ${type}`,
    "string.empty": `${name} ${messgae}`,
    "string.min": `${name} should have a minimum length of {#limit}`,
    "any.required": `${name} ${messgae}`,
  };
};

const AddOranizationValidationSchema = (t) => {
  return Joi.object({
    companyName: Joi.string()
      .required()
      .label(t("company_name"))
      .messages(
        handleMassage(
          t("company_name"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label(t("email"))
      .messages(
        handleMassage(
          t("email"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    username: Joi.string()
      .required()
      .label(t("username"))
      .messages(
        handleMassage(
          t("username"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
    password: Joi.string()
      .required()
      .max(8)
      .min(6)
      .label(t("password"))
      .messages(
        handleMassage(
          t("password"),
          t("is_a_required"),
          t("should_be_a_type_of_'text'")
        )
      ),
  });
};

export { AddOranizationValidationSchema };
