import React, { useEffect, useState } from "react";
import { Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { selfControlLists } from "../../../redux/actions/selfControlListActions";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#f5f5f5",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    padding: 10,
  },
  section: {
    flexDirection: "row",
  },
  table: {
    display: "table",
    width: "100%",
    borderWidth: 0,
    alignSelf: "flex-start",
    marginTop: 25,
  },
  tableRow: {
    flexDirection: "row",
    fontWeight: "normal",
  },
  tableRowHead: {
    flexDirection: "row",
    textTransform: "uppercase",
    color: "#000",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    fontWeight: 900,
  },
  tableCol: {
    width: "33.33%",
    padding: 4,
  },
  tableCell: {
    fontSize: 10,
    textAlign: "center",
  },
  leftBottomTableCol: {
    width: "50%",
    padding: 4,
  },
  leftBottomTableCell: {
    fontSize: 15,
    textAlign: "left",
    margin:"10px"
  },
  rightBottomTableCell: {
    fontSize: 15,
    textAlign: "right",
    marginHorizontal:"60px",
    marginVertical:"10px"

  },
  tableStripped: {
    backgroundColor: "#eeeeee",
  },
  largePage: {
    size: "A2",
    flexDirection: "column",
    backgroundColor: "#f5f5f5",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    padding: 10,
  },
  tableRowLast: {
    borderTopColor: "#000",
    borderTopWidth: 1,
    fontWeight: 900,
  },
  image: {
    width: 100,
    marginTop: 10,
  },
  imgCol: {
    width: "35%",
    textAlign: "center",
  },
  heading: {
    width: "65%",
    marginTop: 20,
  },
  headerSection: {
    flexDirection: "row",
    marginTop: 15,
  },
  headerCol: {
    width: "33%",
    textAlign: "left",
  }
});


const CombinePdf = ({ workorder }) => {
    const [totalAmount, setTotalAmount] = useState(0);
  const { material, customer, contactPerson, deliveryAddress } = workorder;
  const { t } = useTranslation();
  const getSelfControlList = async () => {
     let total = 0;
     material.forEach((item) => {
       total += item.quantity * item.purchasePrice;
     });
     const roundedTotal = total.toFixed(2);

     setTotalAmount(roundedTotal);

  };
  useEffect(()=>{
    getSelfControlList();
  },[])
  return (
    <Document>
     
      <Page size="A2" style={styles.largePage}>
        <View style={styles.section}>
          <View style={styles.imgCol}>
            <Image style={styles.image} src="https://react-pdf.org/images/logo.png" />
          </View>
          <View style={styles.heading}>
            <View style={styles.headerSection}>
              <View style={styles.headerCol}>
                <Text style={{ fontSize: 15, fontWeight: "bold" }}>{t("customer")}</Text>
                <Text style={{ fontSize: 13, fontWeight: "normal" }}>{customer.name}</Text>
              </View>
              <View style={styles.headerCol}>
                <Text style={{ fontSize: 15, fontWeight: "bold" }}>{t("contact_person")}</Text>
                <Text style={{ fontSize: 13, fontWeight: "normal" }}>{contactPerson}</Text>
              </View>
              <View style={styles.headerCol}>
                <Text style={{ fontSize: 15, fontWeight: "bold" }}>{t("address")}</Text>
                <Text style={{ fontSize: 13, fontWeight: "normal" }}>{deliveryAddress}</Text>
              </View>
            </View>
          </View>
        </View>
        { }
        <View style={styles.table}>
        <View style={styles.tableRowHead}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("article_name")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("quantity")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("purchase_price")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("discount")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("discount")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("amount")}</Text>
            </View>

          </View>
          {material?.map((item, index) => (
           <View key={index} style={styles.headerSection}>
           <View style={styles.tableCol}>
             <Text style={styles.tableCell}>
               <Text>{item.name}</Text>
             </Text>
           </View>
           <View style={styles.tableCol}>
             <Text style={styles.tableCell}>
               <Text>{item.quantity}</Text>
             </Text>
           </View>
           <View style={styles.tableCol}>
             <Text style={styles.tableCell}>
               <Text>{item.purchasePrice.toFixed(2)}</Text>
             </Text>
           </View>
           
           <View style={styles.tableCol}>
             <Text style={styles.tableCell}>
               <Text>0</Text>
             </Text>
           </View>
           <View style={styles.tableCol}>
             <Text style={styles.tableCell}>
               <Text>{customer?.discount}</Text>
             </Text>
           </View>
           <View style={styles.tableCol}>
             <Text style={styles.tableCell}>
             <Text>{(item.quantity*item.purchasePrice).toFixed(2)}</Text>
             </Text>
           </View>
         </View>
          ))}
            <View style={{ marginTop:10, borderBottomWidth: 1, borderBottomColor: '#000' }} />

        </View>
        <View  style={styles.headerSection}>
           <View style={styles.leftBottomTableCol}>
             <Text style={styles.leftBottomTableCell}>
               <Text style={{ fontSize: 13, fontWeight: "normal" }}>{t("total_excl_var")}</Text>
             </Text>
             <Text style={styles.leftBottomTableCell}>
               <Text style={{ fontSize: 13, fontWeight: "normal" }}>{t("vat_25_on_")+totalAmount}</Text>
             </Text>
             <Text style={styles.leftBottomTableCell}>
               <Text style={{ fontSize: 15, fontWeight: "bold" }}>{t("to_pay")}</Text>
             </Text>
             <Text style={styles.leftBottomTableCell}>
               <Text style={{ fontSize: 13, fontWeight: "normal" }}>{t("vat")}</Text>
             </Text>
             <Text style={styles.leftBottomTableCell}>
               <Text style={{ fontSize: 15, fontWeight: "bold" }}>{t("rut_rot_green_deduction_incl_vat_total_deduction")}</Text>
             </Text>
           </View>
           <View style={styles.leftBottomTableCol}>
             <Text style={styles.rightBottomTableCell}>
               <Text style={{ fontSize: 13, fontWeight: "normal" }}>{totalAmount}</Text>
             </Text>
             <Text style={styles.rightBottomTableCell}>
               <Text style={{ fontSize: 13, fontWeight: "normal" }}>{(totalAmount*0.25).toFixed(2)}</Text>
             </Text>
             <Text style={styles.rightBottomTableCell}>
               <Text style={{ fontSize: 15, fontWeight: "bold" }}>{(totalAmount*1.25).toFixed(2) +" SEK"  }</Text>
             </Text>
             <Text style={styles.rightBottomTableCell}>
               <Text style={{ fontSize: 13, fontWeight: "normal" }}>{(totalAmount*0.25).toFixed(2) +" SEK"}</Text>
             </Text>
             <Text style={styles.rightBottomTableCell}>
               <Text style={{ fontSize: 15, fontWeight: "bold" }}>0.00</Text>
             </Text>
           </View>

         </View>
         <View style={{ marginTop:6, borderBottomWidth: 1, borderBottomColor: '#000' }} />

      </Page>
    </Document>
  )
};

export default CombinePdf;
