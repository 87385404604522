import { Constants } from "../../constants/Contants";
import request from "../../utils/request";
import { toast } from "react-toastify";

export const getStaffWorkSchedules = async (data) => {
  try {
    const res = await request.post(Constants.get_allStaff_workSchedules, data);
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};
