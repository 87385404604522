import React, {useState, useEffect} from "react";
import { getWholeSellerFilesData } from "../redux/actions/uploadActions";
import DataTable from "react-data-table-component";

const EDIListComponent = () => {

const [filesData, setFilesData] = useState([])
const columns = [
    {
      name: <div className="table-content">{("Suppliers")}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
        >
          {row.content.SyncItOrderResp.OrderHead.SupplierReference}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{("Order Head")}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
        >
          {row.content.SyncItOrderResp.OrderHead.OrderHeadID}
        </div>
      ),
      wrap: true,
    },
  ];

  useEffect(() => {
     const files = async() => {
        const data = await getWholeSellerFilesData()
        setFilesData(data?.data)
     }
     files()
  },[])
  return( 
  <div>
   <div className="table-responsive alternative-colors">
        <DataTable
          columns={columns}
          pagination
          data={filesData}
          responsive={true}
        />
      </div>
  </div>);
};

export default EDIListComponent;
