import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchInventories } from "../../redux/slices/inventorySlice";
import CreateWarehouse from "./CreateWarehouse";
import { Link } from "react-router-dom";

const InventoryList = () => {
    const dispatch = useDispatch();
    const { inventories, loading } = useSelector((state) => state.inventory);

    useEffect(() => {
        dispatch(fetchInventories()); // ✅ Fetch all warehouses when the component loads
    }, [dispatch]);

    return (
        <div>
            <h1>Alla Lager</h1>
            <CreateWarehouse /> {/* ✅ Component to create a warehouse */}

            {loading ? (
                <p>Laddar...</p>
            ) : (
                <ul>
                    {inventories.length > 0 ? (
                        inventories.map((warehouse) => (
                            <li key={warehouse._id}>
                                <Link to={`/dashboard/inventory/${warehouse._id}`}>{warehouse.name}</Link>
                            </li>
                        ))
                    ) : (
                        <p>Inga lager hittades.</p>
                    )}
                </ul>
            )}
        </div>
    );
};

export default InventoryList;
