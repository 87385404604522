import React, { useState, useEffect } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  createSelfControl,
  updateSelfControl,
} from "../../redux/actions/selfControlActions";
import { useLocation, useNavigate } from "react-router-dom";
import { selfControlValidationSchema } from "./selfControlValidationSchema";
import { useTranslation } from "react-i18next";
import { selfControlList } from "../../redux/actions/selfControlActions";

const AddEditSelfControlComponent = () => {
  const [checkpoint, setCheckpoint] = useState("");
  const [reference, setReference] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [selfControlsPoint, setSelfControlsPoint] = useState([]);
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = async () => {
    const formDataToBeValidated = {
      points: checkpoint,
      reference,
    };
    const { error } = selfControlValidationSchema(t).validate(
      formDataToBeValidated
    );

    if (error) {
      error.details.forEach((err) => {
        toast.error(err.message);
      });

      return;
    }
    const payload = {
      points: checkpoint,
      reference,
    };

    if (isEdit) {
      const data = await updateSelfControl(
        state?.selfControlData?._id,
        payload
      );
      if (data.status) {
        toast.success(t("self_control_updated_successfully"));
      }
    } else {
      const data = await createSelfControl(payload);
      if (data.status) {
        toast.success(t("self_control_created_successfully"));
      }
    }
  };

  const getSelfControlList = async () => {
    const selfControlData = await selfControlList();
    console.log(selfControlData,"selfControlList1234");
    if (selfControlData?.status) {
      setSelfControlsPoint(selfControlData?.data);
    }
  };

  useEffect(() => {
    const data = state?.selfControlData;
    if (data) {
      setIsEdit(true);
      setCheckpoint(data.points || "");
      setReference(data.reference || "");
    }
  }, [state]);

  useEffect(() => {
    getSelfControlList();
  }, []);

  return (
    <div className="my-auto">
      <div className="heading">
        <h3>{t("self_control")}</h3>
      </div>
      <div className="row">
        <div className="col-md-12 mx-auto">
          <div className="card_layout">
            <Form>
              <div className="">
                <div className="d-flex">
                  <div className="col-md-4">
                    <Form.Group className="mb-3">
                      <Form.Label>{t("name")}</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="text"
                          value={checkpoint}
                          onChange={(e) => setCheckpoint(e.target.value)}
                        />
                      </InputGroup>
                    </Form.Group>
                  </div>
                  <div className="mx-2 mt-2">
                    <Form.Label>{t("")}</Form.Label>
                    <Button className="simple-btn" onClick={handleSubmit}>
                      {isEdit ? t("Update self control point") : t("Save self control point")}
                    </Button>
                  </div>
                </div>
                <div className="col-md-8">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("reference")}</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        as="textarea"
                        value={reference}
                        onChange={(e) => setReference(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
              </div>
              <div className="d-flex flex-wrap my-2">
                {selfControlsPoint?.map((s) => (
                  <div key={s._id} className="col-md-4 selfControls d-flex align-items-center">
                    <span className="p-1" style={{ fontSize: '12px' }}>{s.points}</span>
                  </div>

                ))}
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditSelfControlComponent;
