import { Constants } from "../../constants/Contants";
import request from "../../utils/request";
import { toast } from "react-toastify";

export const createWorkrole = async (data) => {
  try {
    const res = await request.post(Constants.create_workrole_api_url, data);
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const workroleList = async () => {
  try {
    const res = await request.get(Constants.workrole_list_api_url);
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};

export const updateWorkrole = async (_id, workrole) => {
  try {
    const res = await request.post(Constants.update_workrole_api_url, {
      _id,
      workrole,
    });
    if (res) {
      return res.data;
    }
  } catch (e) {
    toast.error(e.message);
  }
};
