import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedSelfControlList: null,
};

export const slice = createSlice({
  name: "selfControlSlice",
  initialState,
  reducers: {
    selectedSelfControlList: (state, action) => {
      state.selectedSelfControlList = action.payload;
    }
  },
});

export const { selectedSelfControlList } = slice.actions;

export default slice.reducer;
