import React, { useState, useEffect } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { createFavotireList, updateFavoriteList } from "../../redux/actions/favoriteListActions";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MaterialTab from "../workorders/workOrderTabs/MaterialTab";

const AddEditFavoriteListComponent = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isPublic, setIsPublic] = useState(false)
  const userData = useSelector((state) => state.userData.value);
  const [favorites, setFavorites] = useState([])
  const [isEdit, setIsEdit] = useState(false);
  const { state } = useLocation();
  const { t } = useTranslation();
  const [selectedFavorites, setSelectedFavorites] = useState()

  const handleSubmit = async () => {
    if (!name) {
      return toast.error(t("please_enter_name"))
    }
    const payload = {
      name,
      description,
      clientId: userData.clientId,
      user: userData._id,
      isPublic,
      favorites: selectedFavorites
    };

    if (isEdit) {
      const data = await updateFavoriteList(
        state?.favoriteListData?._id,
        payload
      );
      if (data.status) {
        toast.success(t("favorite_list_updated_successfully"));
      }
    } else {
      const data = await createFavotireList(payload);
      if (data?.status) {
        toast.success(t("favorite_list_created_successfully"));
      }
    }
  };

  const handleFavorites = (e) => {
    if (selectedFavorites.includes(e._id)) {
      const filteredFav = selectedFavorites.filter(s => s !== e._id)
      setSelectedFavorites(filteredFav)
    } else {
      setSelectedFavorites([...selectedFavorites, e._id])
    }

  }

  useEffect(() => {
    const data = state?.favoriteListData;
    if (data) {
      setIsEdit(true);
      setName(data.name || "");
      setDescription(data.description || "");
      setFavorites(data.favorites)
      setIsPublic(data.isPublic)
      setSelectedFavorites(data.favorites.map(f => f._id))
    }
  }, [state]);

  return (
    <div className="my-auto">
      <div className="heading">
        <h3>{t("favorite_lists")}</h3>
      </div>
      <div className="row">
        <div className="col-md-12 mx-auto">
          <div className="card_layout">
            <Form>
              <div className="">
                <div className="d-flex">
                  <div className="col-md-4">
                    <Form.Group className="mb-3">
                      <Form.Label>{t("name")}</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </InputGroup>
                    </Form.Group>
                  </div>

                </div>
                <div className="col-md-6">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Description")}</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        as="textarea"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                {/* {console.log("============", favorites)} */}

                {/* {favorites.map((f) => (
                  <div className="d-flex">
                    <span className="">{f.workorder.aoname}</span>
                    {f.materials.map((m) => (
                      <div className="d-flex flex-direction-column">
                        <li>{m.name}</li>
                      </div>
                    ))}
                  </div>
                ))} */}
                <div className="d-flex gap-2 my-2 font-weight-bold">
                  <span for="myCheckbox" className="font-weight-bold">Public:</span>
                  <input type="checkbox" id="myCheckbox" name="myCheckbox" checked={isPublic} onChange={() => setIsPublic(!isPublic)} />
                </div>
                {isEdit &&
                  <div className="favListTable">
                    <div className="selectedFavoriteTable">Pricelist Name</div>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="col-w-150px">{t("article_number")}</th>
                            <th className="col-w-450px">{t("article_name")}</th>
                            <th className="col-w-120px">{t("purcahse_price")}</th>
                            <th className="col-w-120px">{t("selling_price")}</th>
                            <th><i class="fa fa-star-o"></i></th>
                          </tr>
                        </thead>
                        <tbody>
                          {favorites?.map((e, index) => (
                            <tr key={index}>
                              <td>{e.number}</td>
                              <td>{e.name}</td>
                              <td>{+ e?.grossPrice?.replace(/,/g, "") -
                                (+e?.grossPrice?.replace(/,/g, "") / 100) * 10}</td>
                              <td>{e.grossPrice}</td>
                              <td><i class={`fa ${selectedFavorites?.includes(e._id) ? 'fa-star' : 'fa-star-o'}`} onClick={() => handleFavorites(e)} /></td>
                            </tr>
                          ))
                          }
                        </tbody>
                      </table>
                    </div >
                  </div>
                }
              </div>
              <div className="mt-4">
                <Button
                  className="simple-btn mx-auto"
                  onClick={handleSubmit}
                >
                  {isEdit ? t("update") : t("Submit")}
                </Button>
              </div>
            </Form>
            <div className="my-3">
              <MaterialTab addMaterialFromFavorites={true} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditFavoriteListComponent;
