import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { addInventory } from "../../redux/slices/inventorySlice";

const CreateInventory = () => {
    const [name, setName] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post("/api/inventory", { name });
            navigate("/inventory");
        } catch (error) {
            console.error("Fel vid skapande av lager:", error);
        }
    };

    return (
        <div>
            <h2>Skapa Nytt Lager</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Lager Namn"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
                <button type="submit">Skapa</button>
            </form>
        </div>
    );
};

export default CreateInventory;
