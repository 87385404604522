import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { selfControlLists } from "../../../redux/actions/selfControlListActions";
import { selectedSelfControlList } from "../../../redux/slices/selfControlListSlice";
import { useDispatch } from "react-redux";

export default function SelfControlListing() {
  const [selfControlsList, setSelfControlsList] = useState([]);
  const [filteredSelfCotrol, setFilteredSelfControl] = useState([])
  const [querySearch, setQuerySearch] = useState("")
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const columns = [
    {
      name: <div className="table-content">{t("name")}</div>,
      selector: (row) => (
        <div
          className="jobPosition"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/dashboard/add-self-control-list", {
              state: { selfControlDataList: row },
            });
            dispatch(selectedSelfControlList(row))
          }}
        >
          {row.name}
        </div>
      ),
      wrap: true,
    },
  ];

  const getSelfControlList = async () => {
    const selfControlDataList = await selfControlLists();
    if (selfControlDataList?.status) {
      setSelfControlsList(selfControlDataList?.data);
    }
  };

  const handleSearch = (value) => {
    setQuerySearch(value)
    const filterd = selfControlsList?.filter((s) => s.name.toLowerCase().includes(value.toLowerCase()))
    setFilteredSelfControl(filterd)
}


  useEffect(() => {
    getSelfControlList();
  }, []);

  return (
    <div className="customListing">
      <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
        <div className="heading">
          <h3>{t("self_control_list")} </h3>
        </div>
        <div className="d-flex align-items-center input_button">
          <form action="" className="header-search">
            <input type="text" placeholder={t("search")} onChange={(e) => handleSearch(e.target.value)}/>
            <button>
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </form>
          <Link to="/dashboard/add-self-control-list" className="simple-btn">
            {t("add_self_control_list")}
          </Link>
        </div>
      </div>
      <div className="table-responsive alternative-colors">
        <DataTable
          columns={columns}
          pagination
          data={filteredSelfCotrol?.length || querySearch ? filteredSelfCotrol : selfControlsList}
          responsive={true}
          paginationPerPage={40}
          paginationRowsPerPageOptions={[10, 20, 30, 40]}
        />
      </div>
    </div>
  );
}
