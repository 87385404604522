import React, { useState, useEffect } from "react";
import { Button, Form, InputGroup, Tab, Tabs } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  createCustomer,
  updateCustomer,
  getCustomerById,
} from "../../redux/actions/customersActions";
import { useLocation, useNavigate } from "react-router-dom";
import { AddUpdateDeliveryAddressValidationScheme } from "./ValidationSchema";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { selectedWorkorder } from "../../redux/slices/workorderSlice";
import { useSelector } from "react-redux";
import {
  priceListOptions,
  wholesaleLockOptions,
  companyTypes,
} from "../../constants/Contants";
import CustomerWorkOrder from "../workorders/workOrderTabs/CustomerWorkOrder"
import PdfTypeModal from "../workorders/PDFs/PdfsTypeModal";
import CommonButton from "../workorders/workOrderTabs/CommonButton";
import { customerData } from "../../redux/slices/customerSlice";
const AddEditCustomerComponent = ({ workorderData, setActiveTab, exportInvoice }) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [land, setLand] = useState("");
  const [paymentTerms, setPaymentTerms] = useState("");
  const [latePaymentInterest, setLatePaymentInterest] = useState(0);
  const [priceList, setPriceList] = useState("");
  const [discount, setDiscount] = useState(0);
  const [customerNumber0time, setCustomerNumber0time] = useState(0);
  const [customerNumberFortnox, setCustomerNumberFortnox] = useState(0);
  const [customerNumberVisma, setCustomerNumberVisma] = useState(0);
  const [customerNumberOther, setCustomerNumberOther] = useState(0);
  const [username, setUsername] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("#ff00ff");
  const [textColor, setTextColor] = useState("");
  const [wholesaleLock, setWholesaleLock] = useState("");
  const [companyType, setCompanyType] = useState("");
  const [propertyDesignation, setPropertyDesignation] = useState("");
  const [ORGNumber, setORGNumber] = useState(0);
  const [VATNumber, setVATNumber] = useState(0);
  const [BRFNumber, setBRFNumber] = useState(0);
  const [status, setStatus] = useState(true);
  const [customerNotes, setCustomerNotes] = useState("");
  const [workOrderCustomerData, setWorkOrderCustomerData] = useState();
  const [isPdfTypeModalVisible, setIsPdfTypeModalVisible] = useState(false);

  // Delivery Address
  const [addressDelivery, setAddressDelivery] = useState("");
  const [zipCodeDelivery, setZipCodeDelivery] = useState("");
  const [postortDelivery, setPostortDelivery] = useState("");

  //Util
  const [tabKey, setTabKey] = useState("personal");
  const [isEdit, setIsEdit] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentUser = useSelector(state => state?.userData.value)
  const selectedCustomer = useSelector(state => state.customer?.data)
  const fortnoxData = useSelector(state => state.fortnox?.value?.data)


  const handlePhoneChange = (e) => {
    setPhoneNumber(e.target.value);
  };
  const handleSubmit = async () => {
    if (!name) {
      return toast.error(t("please_enter_name"));
    }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const checkValidEmail = emailRegex.test(email)
    if(!checkValidEmail) {
     return toast.error("Please enter a valid email address.")
    }
    const payload = {
      name,
      contact: phoneNumber,
      email,
      address1,
      address2,
      address3,
      zipCode,
      land,
      paymentTerms,
      latePaymentInterest,
      priceList,
      discount,
      customerNumber0time,
      customerNumberFortnox,
      customerNumberVisma,
      customerNumberOther,
      username,
      password,
      backgroundColor,
      textColor,
      wholesaleLock,
      companyType,
      propertyDesignation,
      ORGNumber,
      VATNumber,
      BRF_ORG_Number: BRFNumber,
      notes: customerNotes,
      clientId: currentUser.clientId,
      isActive: status,
    };

    if (isEdit) {
      const data = await updateCustomer(
        selectedCustomer?._id || workOrderCustomerData?._id,
        payload
      );
      if (data?.status) {
        dispatch(customerData(data?.data))
        toast.success(t("customer_updated_successfully"));
      }
    } else {
      const data = await createCustomer(payload, fortnoxData?.access_token);
      if (data?.status) {
       return toast.success(t("customer_created_successfully"));
      }
    }
  };

  const getWorkorderCustomerdata = async () => {
    if (workorderData) {
      const data = await getCustomerById(
        workorderData?.customer?._id || workorderData?.customer
      );
      setWorkOrderCustomerData(data?.data[0]);
    }
  };

  useEffect(() => {
    getWorkorderCustomerdata();
  }, [workorderData]);

  useEffect(() => {
    const data = selectedCustomer || workOrderCustomerData;
    if (data) {
      setIsEdit(true);
      setEmail(data?.email);
      setName(data?.name);
      setPassword(data?.password);
      setPhoneNumber(data?.contact);
      setAddress1(data?.address1);
      setAddress2(data?.address2);
      setAddress3(data?.address3);
      setZipCode(data?.zipCode);
      setLand(data?.land);
      setPaymentTerms(data?.paymentTerms);
      setLatePaymentInterest(
        data?.latePaymentInterest == "" ? 0 : data?.latePaymentInterest
      );
      setPriceList(data?.priceList);
      setDiscount(data?.discount || 0);
      setCustomerNumber0time(data?.customerNumber0time || 0);
      setCustomerNumberFortnox(data?.customerNumberFortnox || 0);
      setCustomerNumberVisma(data?.customerNumberVisma || 0);
      setCustomerNumberOther(data?.customerNumberOther || 0);
      setUsername(data?.username);
      setBackgroundColor(data?.backgroundColor);
      setTextColor(data?.textColor);
      setWholesaleLock(data?.wholesaleLock);
      setCompanyType(data?.companyType);
      setPropertyDesignation(data?.propertyDesignation);
      setORGNumber(data?.ORGNumber || 0);
      setVATNumber(data?.VATNumber || 0);
      setBRFNumber(data?.BRFNumber || 0);
      setStatus(data?.isActive ? true : false);
      setCustomerNotes(data.notes);
    }
  }, [state, workOrderCustomerData]);

  return (
    <div className="my-auto">
      <div className="heading">
        {!workOrderCustomerData && <h3>{t("customer")}</h3>}
      </div>
      <div className="row">
        <div className="col-md-12 mx-auto">
          <div className="card_layout p-0 work-order-wrapper">
            <Tabs
              activeKey={tabKey}
              onSelect={(key) => setTabKey(key)}
              className="mb-3 mt-3"
            >
              {/* Personal Information */}
              <Tab
                eventKey="personal"
                className="p-4"
              >
                <Form className="form-design">
                  <div className="row gx-5">
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("name")} *</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("email")}</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("phone_number")}</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            value={phoneNumber}
                            onChange={handlePhoneChange}
                            type="number"
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          {t("address")} #1 ({t("billing_address")})
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            value={address1}
                            type="text"
                            onChange={(e) => setAddress1(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("address")} #2</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            value={address2}
                            type="text"
                            onChange={(e) => setAddress2(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("city")}</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            value={address3}
                            type="text"
                            onChange={(e) => setAddress3(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("land")}</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            value={land}
                            type="text"
                            onChange={(e) => setLand(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("payment_terms")}</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            value={paymentTerms}
                            type="text"
                            onChange={(e) => setPaymentTerms(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("late_payment_interest")}</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            value={latePaymentInterest}
                            type="text"
                            onChange={(e) =>
                              setLatePaymentInterest(e.target.value)
                            }
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("price_list")}</Form.Label>
                        <select
                          className="form-select mb-3"
                          onChange={(e) => setPriceList(e.target.value)}
                          value={priceList}
                        >
                          <option value={""} selected>
                            {t("select_price_list")}
                          </option>
                          {priceListOptions.map((p) => (
                            <option value={p.value}>{p.name}</option>
                          ))}
                        </select>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("discount")} (%)</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            value={discount}
                            type="number"
                            onChange={(e) => setDiscount(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          {t("customer_number")} (0 {t("time")})
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            value={customerNumber0time}
                            type="text"
                            onChange={(e) =>
                              setCustomerNumber0time(e.target.value)
                            }
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          {t("customer_number")} ({t("fortnox")})
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            value={customerNumberFortnox}
                            type="number"
                            onChange={(e) =>
                              setCustomerNumberFortnox(e.target.value)
                            }
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          {t("customer_number")} ({t("visma")})
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            value={customerNumberVisma}
                            type="number"
                            onChange={(e) =>
                              setCustomerNumberVisma(e.target.value)
                            }
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          {t("customer_number")} ({t("other")})
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            value={customerNumberOther}
                            type="number"
                            onChange={(e) =>
                              setCustomerNumberOther(e.target.value)
                            }
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>

                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("background_color")}</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            value={backgroundColor}
                            type="color"
                            // value="#ff00ff"
                            onChange={(e) => setBackgroundColor(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("text_color")}</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            value={textColor}
                            type="color"
                            onChange={(e) => setTextColor(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("wholesale_lock")}</Form.Label>
                        <select
                          className="form-select mb-3"
                          onChange={(e) => setWholesaleLock(e.target.value)}
                          value={wholesaleLock}
                        >
                          <option value={""} selected>
                            {t("select_wholesale_lock")}
                          </option>
                          {wholesaleLockOptions.map((w) => (
                            <option value={w.value}>{w.name}</option>
                          ))}
                        </select>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("company_type")}</Form.Label>
                        <select
                          className="form-select mb-3"
                          onChange={(e) => setWholesaleLock(e.target.value)}
                          value={wholesaleLock}
                        >
                          <option value={""} selected>
                            {t("select_comapny_type")}
                          </option>
                          {companyTypes.map((w) => (
                            <option value={w.value}>{w.name}</option>
                          ))}
                        </select>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("property_designation")}</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            value={propertyDesignation}
                            type="text"
                            onChange={(e) =>
                              setPropertyDesignation(e.target.value)
                            }
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("ORG_number")}</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            value={ORGNumber}
                            type="number"
                            onChange={(e) => setORGNumber(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("VAT_number")}</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            value={VATNumber}
                            type="number"
                            onChange={(e) => setVATNumber(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("BRF_ORG_number")}</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            value={BRFNumber}
                            type="number"
                            onChange={(e) => setBRFNumber(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("status")}</Form.Label>
                        <select
                          className="form-select mb-3"
                          onChange={(e) => setStatus(e.target.value)}
                          value={status}
                        >
                          <option value={true} selected>
                            {t("active")}
                          </option>
                          <option value={false}>{t("inactive")}</option>
                        </select>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("notes")}</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            as="textarea"
                            rows={5}
                            value={customerNotes}
                            onChange={(e) => setCustomerNotes(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                  </div>
                  {!workOrderCustomerData && (
                    <div className="d-flex justify-content-start">
                      <div className="mt-4 mx-2">
                        <Button
                          className="simple-btn mx-auto"
                          onClick={handleSubmit}
                        >
                          {isEdit ? t("update") : t("save")}
                        </Button>
                      </div>
                    </div>
                  )}
                </Form>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      {workOrderCustomerData && (
        <div>
          <CommonButton
            handleSubmit={handleSubmit}
            setIsPdfTypeModalVisible={setIsPdfTypeModalVisible}
            exportInvoice={exportInvoice}
          />
        </div>
      )}
      <CustomerWorkOrder
        customerId={
          workorderData ? workorderData?.customer?._id : customerData?._id
        }
        setActiveTab={setActiveTab}
      />
      <PdfTypeModal
        isPdfTypeModalVisible={isPdfTypeModalVisible}
        setIsPdfTypeModalVisible={setIsPdfTypeModalVisible}
      />
    </div>
  );
};

export default AddEditCustomerComponent;
