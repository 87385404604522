import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const inventorySlice = createSlice({
    name: "inventory",
    initialState: { inventories: [], inventory: { materials: [] }, loading: false },
    reducers: {
        setInventories: (state, action) => {
            state.inventories = action.payload;
        },
        setInventory: (state, action) => {
            state.inventory = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        addInventoryToState: (state, action) => {
            state.inventories.push(action.payload);
        },
        addMaterialToState: (state, action) => {
            if (!state.inventory.materials) {
                state.inventory.materials = [];
            }
            state.inventory.materials.push(action.payload);
        },
        removeMaterialFromState: (state, action) => {
            state.inventory.materials = state.inventory.materials.filter((_, index) => index !== action.payload);
        }
    }
});

// ✅ Export Redux actions
export const {
    setInventories,
    setInventory,
    setLoading,
    addInventoryToState,
    addMaterialToState,
    removeMaterialFromState
} = inventorySlice.actions;

// ✅ Fetch all warehouses
export const fetchInventories = () => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const response = await axios.get("/api/inventory");
        dispatch(setInventories(response.data));
    } catch (error) {
        console.error("Fel vid hämtning av lager", error.response?.data || error);
    }
    dispatch(setLoading(false));
};

// ✅ Fetch inventory details by ID
export const fetchInventoryDetails = (id) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const response = await axios.get(`/api/inventory/${id}`);
        dispatch(setInventory(response.data));
    } catch (error) {
        console.error("Fel vid hämtning av lagerdetaljer", error.response?.data || error);
    }
    dispatch(setLoading(false));
};

// ✅ Add material to an inventory
export const addMaterial = (inventoryId, material) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const response = await axios.put(`/api/inventory/${inventoryId}/material`, { material });

        dispatch(addMaterialToState(response.data)); // ✅ Update Redux state
        dispatch(fetchInventoryDetails(inventoryId)); // ✅ Ensure UI updates correctly
    } catch (error) {
        console.error("Fel vid tillägg av material", error.response?.data || error);
    }
    dispatch(setLoading(false));
};

export const createInventory = (inventoryData) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        // ✅ Ensure the name is not empty or null before sending to the API
        if (!inventoryData.name || inventoryData.name.trim() === "") {
            throw new Error("Warehouse name cannot be empty!");
        }

        const response = await axios.post("/api/inventory", inventoryData, {
            headers: { "Content-Type": "application/json" },
        });

        dispatch(addInventoryToState(response.data)); // ✅ Add to Redux state
        dispatch(fetchInventories()); // ✅ Refresh inventory list
    } catch (error) {
        console.error("Fel vid skapande av lager", error.response?.data || error);
    }
    dispatch(setLoading(false));
};


// ✅ Delete material from inventory
export const deleteMaterial = (inventoryId, materialIndex) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        await axios.delete(`/api/inventory/${inventoryId}/material/${materialIndex}`);

        dispatch(removeMaterialFromState(materialIndex)); // ✅ Remove from Redux state
        dispatch(fetchInventoryDetails(inventoryId)); // ✅ Ensure state updates correctly
    } catch (error) {
        console.error("Fel vid borttagning av material", error.response?.data || error);
    }
    dispatch(setLoading(false));
};

export default inventorySlice.reducer;
