/** @format */

import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux/es/hooks/useSelector";
import {
  STATUSES,
  PRICETYPES,
  priceListOptions,
  wholesaleLockOptions,
  companyTypes,
  OFFER_STATUSES,
} from "../../../constants/Contants";
import {
  createWorkorder,
  updateWorkorder,
} from "../../../redux/actions/workorderActions";
import {
  getCustomerById,
  customerList,
  createCustomer,
} from "../../../redux/actions/customersActions";
import { selectedWorkorder } from "../../../redux/slices/workorderSlice";
import { updateCustomer } from "../../../redux/actions/customersActions";
import { priorityList } from "../../../redux/actions/priorityActions";
import PdfTypeModal from "../PDFs/PdfsTypeModal";
import { useIsExportInvoiceVisible } from "../../CommonDisableFunction";
import { isLoading } from "../../../redux/slices/loadersSlice";
const WorkorderInformationTab = ({
  isEdit,
  setIsEdit,
  allCustomers,
  allProjects,
  workorderData,
  getDataList,
  getCustomerDetail,
  setAllCustomers,
  allStaff,
  selectedSelfControlsListPoints,
  customerDeliveryAddress,
  customerIdForNewWorkorder,
  exportInvoice,
  offerType,
}) => {
  // Work Order Information
  const [customer, setCustomer] = useState("");
  const [workorderCustomer, setWorkorderCustomer] = useState("");
  const [aoname, setAOName] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [postort, setPostort] = useState("");
  const [priority, setPriority] = useState("");
  const [status, setStatus] = useState("Ongoing");
  const [owner, setOwner] = useState("");
  const [aonumber, setAONumber] = useState("");
  const [drivingCompensation, setDrivingCompensation] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const [textColor, setTextColor] = useState("");
  const [priceType, setPriceType] = useState("");
  const [propertyDesignation, setPropertyDesignation] = useState("");
  const [ORGNumber, setORGNumber] = useState(0);
  const [fixedPrice, setFixedPrice] = useState(0);
  const [VATNumber, setVAtNumber] = useState(0);
  const [BRF_ORG_Number, setBRF_ORG_Number] = useState(0);
  const [project, setProject] = useState("");
  const [personnelID, setpersonnelID] = useState("");
  const [eonumber, setEONumber] = useState(0);
  const [notesOnWorkorder, setNotesOnWorkorder] = useState("");
  const [notesAboutCustomer, setNotesAboutCustomer] = useState("");
  const [uploadedDocuments, setUploadedDocument] = useState([]);
  const [customerDetail, setCustomerDetail] = useState({});
  const [priorities, setPriorities] = useState([]);

  // add customer
  const [isAddCus, setIsAddCus] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [wholesaleLock, setWholesaleLock] = useState("");
  const [CompanyTypes, setCompanyTypes] = useState("");
  const [statusCustomer, setStatusCustomer] = useState(true);
  const [phoneNumberCustomer, setPhoneNumberCustomer] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [land, setLand] = useState("");
  const [paymentTerms, setPaymentTerms] = useState("");
  const [latePaymentInterest, setLatePaymentInterest] = useState(0);
  const [priceList, setPriceList] = useState("");
  const [discount, setDiscount] = useState(0);
  const [isPdfTypeModalVisible, setIsPdfTypeModalVisible] = useState(false);

  const { t } = useTranslation();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.userData.value);
  const selectedWorkorderData = useSelector(
    (state) => state.workorder.selectedWorkorder
  );
  console.log(workorderData, "selectedWorkorderData123");
  useEffect(() => {
    const data = workorderData;
    if (data) {
      if (workorderData && !customerDetail?._id) {
        setIsEdit(true);
        setCustomer(data.customer?._id || "");
        setWorkorderCustomer(data.customer?._id);
      }
      setPriority(data.priority || "");
      setProject(data.project?._id || "");
      setStatus(data.status || "");
      setDrivingCompensation(data.drivingCompensation || "");
      setPriceType(data.priceType || "");
      setAONumber(data.aonumber);
      setAOName(data.aoname || "");
      setContactPerson(data.contactPerson || "");
      setOwner(data.owner || "");
      setpersonnelID(data.personnelID || "");
      setEONumber(data.eonumber || "");
      setNotesOnWorkorder(data.notesOnWorkorder);
      setNotesAboutCustomer(data.notesAboutCustomer);
      setFixedPrice(data.fixedPrice || 0);
      setUploadedDocument(data.document);
      // setTimeReportList(data.timeReports);

      setEmail(data.email);
      setPhoneNumber(data.phoneNumber);
      setDeliveryAddress(data.deliveryAddress || "");
      setZipCode(data.zipCode || "");
      setPostort(data.postort || "");
      setBackgroundColor(data.backgroundColor || "");
      setTextColor(data.textColor || "");
      setPropertyDesignation(data.propertyDesignation || "");
      setORGNumber(data.ORGNumber || 0);
      setVAtNumber(data.VATNumber || 0);
      setBRF_ORG_Number(data.BRF_ORG_Number || 0);
    }
  }, [workorderData]);

  useEffect(() => {
    const data = customerDetail;
    if (JSON.stringify(data) !== "{}") {
      if (customerIdForNewWorkorder) {
        setCustomer(customerIdForNewWorkorder);
      }
      setEmail(data.email || "");
      setPhoneNumber(data.phoneNumber || data.contact);
      setDeliveryAddress(data.deliveryAddress || "");
      setZipCode(data.zipCode || "");
      setPostort(data.postort || "");
      setBackgroundColor(data.backgroundColor || "");
      setTextColor(data.textColor || "");
      setPropertyDesignation(data.propertyDesignation || "");
      setORGNumber(data.ORGNumber || 0);
      setVAtNumber(data.VATNumber || 0);
      setBRF_ORG_Number(data.BRF_ORG_Number || 0);
      setNotesAboutCustomer(data.notes);
    }
  }, [customerDetail]);

  useEffect(() => {
    if (customerIdForNewWorkorder) {
      getCustomerDataById(customerIdForNewWorkorder);
    }
  }, [customerIdForNewWorkorder]);

  const getCustomerDataById = async (customerId) => {
    const data = await getCustomerById(customerId);
    if (data?.data?.length) {
      setCustomerDetail(data?.data[0]);
      getCustomerDetail(data?.data[0]);
    }
  };

  const handleSaveNotes = async (field) => {
    const workoutPayload = {
      notesOnWorkorder,
    };
    const customerPayload = {
      notesAboutCustomer,
    };
    const data = await updateWorkorder(
      workorderData?._id,
      field === "notesOnWorkorder" ? workoutPayload : customerPayload
    );
    if (data.status) {
      toast.success(t("notes_added_successfully"));
      dispatch(selectedWorkorder(data?.data));
      if (!field) {
        const customerPayload = {
          notes: notesAboutCustomer,
        };
        await updateCustomer(workorderData?.customer?._id, customerPayload);
      }
    }
  };

  const handlePhoneChange = (value) => {
    setPhoneNumberCustomer(value);
  };

  const workorderApi = async (cusId) => {
    if (!aoname) {
      return toast.error(t("please_enter_aoname"));
    }

    const payloadWorkOrder = {
      customer: isAddCus ? cusId : customer ? customer : null,
      aoname,
      contactPerson,
      email,
      phoneNumber,
      deliveryAddress,
      zipCode,
      postort,
      priority,
      status : offerType ? "New offer" : status,
      owner,
      drivingCompensation,
      backgroundColor,
      textColor,
      priceType,
      propertyDesignation,
      ORGNumber,
      fixedPrice,
      VATNumber,
      BRF_ORG_Number,
      project: project ? project : null,
      personnelID,
      eonumber,
      notesOnWorkorder,
      clientId: currentUser.clientId,
    };

    if (
      selectedSelfControlsListPoints.length > 0 &&
      (status === "Completed" || status === "Invoiced")
    ) {
      toast.error("Please unselect self control list");
    } else {
      if (isEdit) {
        const data = await updateWorkorder(
          workorderData?._id,
          payloadWorkOrder
        );
        if (data.status) {
          setWorkorderCustomer(data.data.customer);
          setCustomer(data.data.customer?._id);
          toast.success(t("work_order_updated_successfully"));
          dispatch(
            selectedWorkorder({
              ...data.data,
              customer: data.data.customer,
            })
          );
        }
      } else {
        const data = await createWorkorder(payloadWorkOrder);
        if (data?.status) {
            dispatch(
              selectedWorkorder({
                ...data.data,
                customer: { _id: data.data.customer },
              })
            );
            setIsEdit(true);
            toast.success(t("work_order_created_successfully"));
        }
      }
    }
  };

  const handleSubmit = async (status, callback) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const checkValidEmail = emailRegex.test(email)
    if(!checkValidEmail) {
     return toast.error("Please enter a valid email address.")
    }
    if(isAddCus && !customerName) {
      return toast.error("Customer name is required")
    }
    dispatch(isLoading(true))
    if (isAddCus === true) {
      const payloadCus = {
        name: customerName,
        contact: phoneNumber,
        email,
        address2,
        address3,
        zipCode,
        land,
        paymentTerms,
        latePaymentInterest,
        priceList,
        discount,
        backgroundColor,
        textColor,
        wholesaleLock,
        CompanyTypes,
        propertyDesignation,
        ORGNumber,
        VATNumber,
        clientId: currentUser.clientId
        // isActive: status,
      };
      if (isAddCus && !aoname) {
        return toast.error("Please enter workorder name");
      }
      const data = await createCustomer(payloadCus);
      if (data.status) {
        toast.success(
          t("customer") + " & " + t("work_order_created_successfully")
        );
        workorderApi(data?.data._id);
        setCustomer(data?.data._id);
        getCustomerWithNewlyAddedCustomer();
        setIsAddCus(false);
      } else {
        toast.error(data?.message);
      }
    } else {
      workorderApi();
    }
    if (callback) callback();
    dispatch(isLoading(false))
  };

  const getCustomerWithNewlyAddedCustomer = async () => {
    const data = await customerList(currentUser?.clientId);
    setAllCustomers(data?.data);
  };

  const getPriorityList = async () => {
    const data = await priorityList();
    setPriorities(data?.data);
  };

  const handleCreateApprovedWorkorder = async (status) => {
    const confirmed = window.confirm(
      `Are you sure you want to ${status}`
    );
    if (confirmed) {
      const workorderpayload = { ...selectedWorkorderData };
      const payload = {
        ...workorderpayload,
        status: status,
      };
      const data = await updateWorkorder(workorderpayload?._id, payload);
      if (data?.status) {
        dispatch(selectedWorkorder(data?.data));
        toast.success("Offer updated successfully")
      }
    }
  };

  useEffect(() => {
    getDataList();
    getPriorityList();
  }, []);

  useLayoutEffect(() => {
    if (state?.addedNewCustomer) {
      getCustomerWithNewlyAddedCustomer();
      setCustomer(state?.newlyCustomerId);
    }
  }, [state]);
  const isExportInvoiceVisible = useIsExportInvoiceVisible();

  return (
    <>
      <Form className="form-design">
        <div className="row gx-5">
          <div className="col-lg-4">
            <Form.Group className="mb-3">
              <div className="d-flex justify-content-between align-items-center">
                <Form.Label>{t("customer")}</Form.Label>
                {isEdit ? (
                  <>
                    <div>
                      <button
                        type="button"
                        className="simple-btn mb-1 py-1 workorder-import-btn"
                        onClick={() => {
                          getCustomerDataById(workorderCustomer);
                          setCustomer(workorderCustomer);
                        }}>
                        {t("import")}
                      </button>
                    </div>
                  </>
                ) : null}
              </div>
              <div className="d-flex align-items-baseline flex-nowrap">
                <select
                  className="form-select w-100"
                  onChange={(e) => {
                    if (e.target.value !== "addCus") {
                      getCustomerDataById(e.target.value);
                      setCustomer(e.target.value);
                    }
                    if (e.target.value === "addCus") {
                      setCustomer(e.target.value);
                      setIsAddCus(true);
                    } else {
                      setIsAddCus(false);
                    }
                  }}
                  value={customer}>
                  <option value={""} selected>
                    Select customer
                  </option>
                  <option value="addCus">Add customer +</option>
                  {allCustomers?.map((c) => (
                    <option value={c._id}>{c.name}</option>
                  ))}
                </select>
              </div>
            </Form.Group>
          </div>
          <div className="col-lg-4">
            <Form.Group className="mb-3">
              <Form.Label>{t(offerType || !isExportInvoiceVisible ? "offer name" : "ao_name")}</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  value={aoname}
                  onChange={(e) => setAOName(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </div>
          {isAddCus ? (
            <>
              <div className="col-lg-4  bg-primary-subtle">
                <Form.Group className="mb-3">
                  <Form.Label>
                    {t("customer")} {t("name")} *
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Enter Customer name..."
                      value={customerName}
                      onChange={(e) => setCustomerName(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-lg-4  bg-primary-subtle">
                <Form.Group className="mb-3">
                  <Form.Label>{t("wholesale_lock")}</Form.Label>
                  <select
                    className="form-select mb-3"
                    onChange={(e) => setWholesaleLock(e.target.value)}
                    value={wholesaleLock}>
                    <option value={""} selected>
                      {t("select_wholesale_lock")}
                    </option>
                    {wholesaleLockOptions.map((w) => (
                      <option value={w.value}>{w.name}</option>
                    ))}
                  </select>
                </Form.Group>
              </div>
            </>
          ) : null}
          <div className="col-lg-4">
            <Form.Group className="mb-3">
              <Form.Label>{t(offerType || !isExportInvoiceVisible ? "offer number" : "ao_number")}</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control type="text" value={aonumber} />
              </InputGroup>
            </Form.Group>
          </div>
          <div className="col-lg-4">
            <Form.Group className="mb-3">
              <Form.Label>{t("contact_person")}</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  value={contactPerson}
                  onChange={(e) => setContactPerson(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </div>
          {isAddCus ? (
            <>
              <div className="col-lg-4  bg-primary-subtle">
                <Form.Group className="mb-3">
                  <Form.Label>{t("company_type")}</Form.Label>
                  <select
                    className="form-select mb-3"
                    onChange={(e) => setCompanyTypes(e.target.value)}
                    value={CompanyTypes}>
                    <option value={""} selected>
                      {t("select_comapny_type")}
                    </option>
                    {companyTypes.map((w) => (
                      <option value={w.value}>{w.name}</option>
                    ))}
                  </select>
                </Form.Group>
              </div>
            </>
          ) : null}
          <div className="col-lg-4">
            <Form.Group className="mb-3">
              <Form.Label>{t("email")}</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </div>
          {isAddCus ? (
            <>
              <div className="col-lg-4  bg-primary-subtle">
                <Form.Group className="mb-3">
                  <Form.Label>{t("status")}</Form.Label>
                  <select
                    className="form-select mb-3"
                    onChange={(e) => setStatusCustomer(e.target.value)}
                    value={statusCustomer}>
                    <option value={true} selected>
                      {t("active")}
                    </option>
                    <option value={false}>{t("inactive")}</option>
                  </select>
                </Form.Group>
              </div>
              <div className="col-lg-4 bg-primary-subtle">
                <Form.Group className="mb-3">
                  <Form.Label>{t("phone_number")} # 2</Form.Label>
                  <InputGroup className="mb-3">
                    <PhoneInput
                      country={"us"}
                      value={phoneNumberCustomer}
                      onChange={handlePhoneChange}
                      enableSearch={true}
                      dropdownStyle={{
                        color: "black",
                      }}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-lg-4  bg-primary-subtle">
                <Form.Group className="mb-3">
                  <Form.Label>{t("address")} #2</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      value={address2}
                      onChange={(e) => setAddress2(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-lg-4  bg-primary-subtle">
                <Form.Group className="mb-3">
                  <Form.Label>{t("city")}</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      value={address3}
                      onChange={(e) => setAddress3(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-lg-4  bg-primary-subtle">
                <Form.Group className="mb-3">
                  <Form.Label>{t("land")}</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      value={land}
                      onChange={(e) => setLand(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-lg-4  bg-primary-subtle">
                <Form.Group className="mb-3">
                  <Form.Label>{t("payment_terms")}</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      value={paymentTerms}
                      onChange={(e) => setPaymentTerms(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-lg-4  bg-primary-subtle">
                <Form.Group className="mb-3">
                  <Form.Label>{t("late_payment_interest")}</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      value={latePaymentInterest}
                      placeholder="Enter payment terms..."
                      onChange={(e) => setLatePaymentInterest(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-lg-4  bg-primary-subtle">
                <Form.Group className="mb-3">
                  <Form.Label>{t("price_list")}</Form.Label>
                  <select
                    className="form-select mb-3"
                    onChange={(e) => setPriceList(e.target.value)}
                    value={priceList}>
                    <option value={""} selected>
                      {t("select_price_list")}
                    </option>
                    {priceListOptions.map((p) => (
                      <option value={p.value}>{p.name}</option>
                    ))}
                  </select>
                </Form.Group>
              </div>
              <div className="col-lg-4  bg-primary-subtle">
                <Form.Group className="mb-3">
                  <Form.Label>{t("discount")} (%)</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="number"
                      value={discount}
                      onChange={(e) => setDiscount(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
            </>
          ) : null}
          <div className="col-lg-4">
            <Form.Group className="mb-3">
              <Form.Label>{t("phone_number")}</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </div>
          <div className="col-lg-4">
            <Form.Group className="mb-3">
              <Form.Label>{t("delivery_address")}</Form.Label>
              <select
                className="form-select mb-3"
                onChange={(e) => setDeliveryAddress(e.target.value)}
                value={deliveryAddress}>
                {customerDeliveryAddress?.length && customerDeliveryAddress[0]?.deliveryAdresses.map((c) => (
                  <option key={c._id} value={c.deliveryAddress}>
                    {c.deliveryAddress}
                  </option>
                ))}
              </select>
            </Form.Group>
          </div>
          <div className="col-lg-4">
            <Form.Group className="mb-3">
              <Form.Label>{t("zip_code")}</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </div>
          {isExportInvoiceVisible &&
          <div className="col-lg-4">
            <Form.Group className="mb-3">
              <Form.Label>{t("status")}</Form.Label>
              <select
                className="form-select mb-3"
                onChange={(e) => setStatus(e.target.value)}
                value={status}>
                {(offerType ? [...STATUSES, ...OFFER_STATUSES] : STATUSES)?.map(
                  (c) => (
                    <option key={c.label} value={c.value}>
                      {c.label}
                    </option>
                  )
                )}
              </select>
            </Form.Group>
          </div>
          }
          {!offerType && (
            <>
              <div className="col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>{t("posport")}</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      value={postort}
                      onChange={(e) => setPostort(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>{t("priority")}</Form.Label>
                  <select
                    className="form-select mb-3"
                    onChange={(e) => setPriority(e.target.value)}
                    value={priority}>
                      <option value="">Select priority</option>
                    {priorities?.map((c) => (
                      <option key={c._id} value={c.name}>
                        {c.name}
                      </option>
                    ))}
                  </select>
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>{t("owner")}</Form.Label>
                  <select
                    className="form-select mb-3"
                    onChange={(e) => setOwner(e.target.value)}
                    value={owner}>
                    <option value={""} selected>
                      Select owner
                    </option>
                    {allStaff?.map((c) => (
                      <option value={c._id}>{c.firstName}</option>
                    ))}
                  </select>
                </Form.Group>
              </div>
            </>
          )}
          {!offerType &&  (
            <>
            {isExportInvoiceVisible &&
              <div className="col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>{t("eo_number")}</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="number"
                      value={eonumber}
                      onChange={(e) => setEONumber(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              }
              <div className="col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>{t("driving_compensation")}</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      value={drivingCompensation}
                      onChange={(e) => setDrivingCompensation(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>{t("background_color")}</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="color"
                      value={backgroundColor}
                      onChange={(e) => setBackgroundColor(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>{t("text_color")}</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="color"
                      value={textColor}
                      onChange={(e) => setTextColor(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>{t("price_type")}</Form.Label>
                  <select
                    className="form-select mb-3"
                    onChange={(e) => setPriceType(e.target.value)}
                    value={priceType}>
                    <option value={""} selected>
                      Select type
                    </option>
                    {PRICETYPES?.map((c) => (
                      <option key={c.label} value={c.value}>
                        {c.label}
                      </option>
                    ))}
                  </select>
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>{t("property_designation")}</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      value={propertyDesignation}
                      onChange={(e) => setPropertyDesignation(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>{t("project")}</Form.Label>
                  <select
                    className="form-select mb-3"
                    onChange={(e) => setProject(e.target.value)}
                    value={project}>
                    <option value={""} selected>
                      Select project
                    </option>
                    {allProjects?.map((c) => (
                      <option key={c._id} value={c._id}>
                        {c.name}
                      </option>
                    ))}
                  </select>
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>{t("pernonnel_id")}</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      value={personnelID}
                      onChange={(e) => setpersonnelID(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </div>

              {priceType === "Fixed price" || fixedPrice ? (
                <div className="col-lg-4">
                  <Form.Group className="mb-3">
                    <Form.Label>Fixed Price</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="number"
                        value={fixedPrice}
                        onChange={(e) => setFixedPrice(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
              ) : null}
              <div className="col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>{t("org_number")}</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="number"
                      value={ORGNumber}
                      onChange={(e) => setORGNumber(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>{t("vat_number")}</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="number"
                      value={VATNumber}
                      onChange={(e) => setVAtNumber(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>{t("brf_org_number")}</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="number"
                      value={BRF_ORG_Number}
                      onChange={(e) => setBRF_ORG_Number(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
            </>
          )}
          {offerType ? (
            <div className="col-lg-7">
              <Form.Group className="mb-3">
                <Form.Label>{t("Offer description")}</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    as="textarea"
                    rows={5}
                    value={notesOnWorkorder}
                    onChange={(e) => setNotesOnWorkorder(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </div>
          ) : (
            <div className="row g-5">
              <div className="col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>{t("notes_on_workorder")}</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      as="textarea"
                      rows={5}
                      value={notesOnWorkorder}
                      onChange={(e) => setNotesOnWorkorder(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
                {workorderData?._id && (
                  <Button
                    className="mb-3 simple-btn"
                    onClick={() => handleSaveNotes("notesOnWorkorder")}>
                    {selectedWorkorderData?.status.toLowerCase() ===
                      "new offer" ||
                    selectedWorkorderData?.status.toLowerCase() ===
                      "approved offer" ||
                    selectedWorkorderData?.status.toLowerCase() ===
                      "denied offer"
                      ? t(
                          "save notes on " +
                            selectedWorkorderData.status.toLowerCase()
                        )
                      : t("save_notes_on_workorder")}
                  </Button>
                )}
              </div>
              <div className="col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>{t("notes_about_customer")}</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      as="textarea"
                      rows={5}
                      value={notesAboutCustomer}
                      onChange={(e) => setNotesAboutCustomer(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
                {workorderData?._id && (
                  <Button
                    className="simple-btn"
                    onClick={() => handleSaveNotes()}>
                    {t("save_notes_about_customer")}
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </Form>

      <div
        className="w-100 p-3 work-order-bottom-btn flex justify-content-start gap-3 position-fixed bottom-0 bg-light"
        style={{ zIndex: "1" }}>
        <div className="work-order-bottom-btns">
          <Button className="simple-btn mx-auto" onClick={handleSubmit} >
            Save
            <i className="fa fa-save ms-2 fs-5"></i>
          </Button>
          {!offerType && isEdit &&
          <Button
            className="simple-btn mx-auto"
            onClick={() => {
              setIsPdfTypeModalVisible(true);
            }}>
            PDF View
          </Button>}
          {isExportInvoiceVisible && !offerType &&  isEdit &&  (
            <Button className="simple-btn mx-auto" onClick={exportInvoice}>
              Export Invoice
              <i className="fa fa-save ms-2 fs-5"></i>
            </Button>
          )}
          {!offerType && isEdit &&
          <>
            {(selectedWorkorderData?.status.toLowerCase() === "approved offer" || selectedWorkorderData?.status.toLowerCase() === "new offer" || selectedWorkorderData?.status.toLowerCase() === "denied offer") && 
            <>
              <Button
                className="simple-btn mx-auto"
                onClick={() => handleCreateApprovedWorkorder("Approved Offer")}>
                Approve Offer
                <i className="fa fa-folder-open-o ms-2 fs-5"></i>
              </Button>
              <Button
                className="simple-btn mx-auto"
                onClick={() => handleCreateApprovedWorkorder("Denied Offer")}>
                Denied Offer
                <i className="fa fa-folder-open-o ms-2 fs-5"></i>
              </Button>
            </>
           }
          </>
          }
        </div>
      </div>

      <PdfTypeModal
        isPdfTypeModalVisible={isPdfTypeModalVisible}
        setIsPdfTypeModalVisible={setIsPdfTypeModalVisible}
      />
    </>
  );
};

export default WorkorderInformationTab;
