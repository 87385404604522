import { Constants } from "../../constants/Contants";
import request from "../../utils/request";

export const fileUpload_Generic = async (data) => {
  try {
    const res = await request.post(Constants.file_upload_generic_api_url, data);
    if (res) {
      return res.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getWholeSellerFiles = async () => {
  try {
    const res = await request.get(Constants.whole_seller_files);
    if (res) {
      return res.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getWholeSellerFilesData = async () => {
  try {
    const res = await request.get(Constants.whole_seller_files_data);
    if (res) {
      return res.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};
