import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal } from "react-bootstrap";
import MaterialPdf from './MaterielPdf';
import TimereportPdf from "./TimereportPdf";
import CombinePdf from './CombinePdf';
import { PDFViewer } from "@react-pdf/renderer";
import { useIsExportInvoiceVisible } from '../../CommonDisableFunction';
import OfferPdf from './OfferPdf'
const PdfTypeModal = ({ isPdfTypeModalVisible, setIsPdfTypeModalVisible }) => {
  const isExportInvoiceVisible = useIsExportInvoiceVisible();

  const [isCombiinePdfModalVisible, setIsCombinePdfModalVisible] =
    useState(false);
  const [isMaterielPdfModalVisible, setIsMaterielPdfModalVisible] =
    useState(false);
  const [isTimereportPdfModalVisible, setIsTimereportPdfModalVisible] =
    useState(false);
  const [isDummyreportPdfModalVisible, setIsDummyreportPdfModalVisible] =
    useState(false);
    const [isOfferPdfModalVisible, setIsOfferPdfModalVisible] =
    useState(false);

  const selectedWorkorderData = useSelector(
    (state) => state.workorder.selectedWorkorder
  );
  return (
    <>
      {/* CHOSE PDF TYPE MODAL */}
      {!isExportInvoiceVisible ? 
         <Modal
        show={isPdfTypeModalVisible}
        onHide={() => {
          setIsPdfTypeModalVisible(false);
        }}
        centered
      >
        
        <Modal.Header closeButton>
          <Modal.Title>Click to access Offer Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <Button
            className="simple-btn w-100"
            onClick={() => {
              setIsPdfTypeModalVisible(false);
              setIsOfferPdfModalVisible(true);
            }}
          >
            Export PDF
          </Button>
 
        </Modal.Body>
      </Modal>

       



        : (
      <Modal
        show={isPdfTypeModalVisible}
        onHide={() => {
          setIsPdfTypeModalVisible(false);
        }}
        centered
      >
        
        <Modal.Header closeButton>
          <Modal.Title>Please choose PDF type</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <Button
            className="simple-btn w-100"
            onClick={() => {
              setIsPdfTypeModalVisible(false);
              setIsTimereportPdfModalVisible(true);
            }}
          >
            Time report PDF
          </Button>
          <br />
          <Button
            className="simple-btn w-100"
            onClick={() => {
              setIsPdfTypeModalVisible(false);
              setIsMaterielPdfModalVisible(true);
            }}
          >
            Material PDF
          </Button>
          <br />
          <Button
            className="simple-btn w-100"
            onClick={() => {
              setIsPdfTypeModalVisible(false);
              setIsDummyreportPdfModalVisible(true);
            }}
          >
            Combine PDF
          </Button>
        </Modal.Body>
      </Modal>
        )}
      {/* DUMMY PDF */}
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isMaterielPdfModalVisible}
        onHide={() => setIsMaterielPdfModalVisible(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Material PDF
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pdf-btn">
            <PDFViewer className="pdf-viewer">
              <MaterialPdf materielreports={selectedWorkorderData} />
            </PDFViewer>
          </div>
        </Modal.Body>
      </Modal>
      {/* Combine pdf */}
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isDummyreportPdfModalVisible}
        onHide={() => setIsDummyreportPdfModalVisible(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Combine Pdf
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pdf-btn">
            <PDFViewer className="pdf-viewer">
              {/* <DummyPdf/> */}
              <CombinePdf workorder={selectedWorkorderData} />
            </PDFViewer>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isTimereportPdfModalVisible}
        onHide={() => setIsTimereportPdfModalVisible(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Timereport Pdf
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pdf-btn">
            <PDFViewer className="pdf-viewer">
              <TimereportPdf workorder={selectedWorkorderData} />
            </PDFViewer>
          </div>
        </Modal.Body>
      </Modal>


      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isOfferPdfModalVisible}
        onHide={() => setIsOfferPdfModalVisible(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Offer PDF
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pdf-btn">
            <PDFViewer className="pdf-viewer">
              <OfferPdf workorder={selectedWorkorderData} />
            </PDFViewer>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default PdfTypeModal;