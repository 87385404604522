import React from "react";
import { Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";
import moment from "moment";
import { useTranslation } from "react-i18next";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#f5f5f5",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    padding: 10,
  },
  section: {
    flexDirection: "row",
  },
  table: {
    display: "table",
    width: "100%",
    borderWidth: 0,
    alignSelf: "flex-start",
    marginTop: 25,
  },
  tableRow: {
    flexDirection: "row",
    fontWeight: "normal",
  },
  tableRowHead: {
    flexDirection: "row",
    textTransform: "uppercase",
    color: "#000",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    fontWeight: 900,
  },
  tableCol: {
    width: "33.33%",
    padding: 4,
  },
  tableCell: {
    fontSize: 10,
    textAlign: "center",
  },
  tableStripped: {
    backgroundColor: "#eeeeee",
  },
  largePage: {
    size: "A2",
    flexDirection: "column",
    backgroundColor: "#f5f5f5",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    padding: 10,
  },
  tableRowLast: {
    borderTopColor: "#000",
    borderTopWidth: 1,
    fontWeight: 900,
  },
  image: {
    width: 100,
    marginTop: 10,
  },
  imgCol: {
    width: "35%",
    textAlign: "center",
  },
  heading: {
    width: "65%",
    marginTop: 20,
  },
  headerSection: {
    flexDirection: "row",
    marginTop: 15,
  },
  headerCol: {
    width: "33%",
    textAlign: "left",
  }
});


const MaterielPdf = ({ materielreports }) => {
  const { t } = useTranslation();
  return (
    <Document>
      <Page size="A2" style={styles.largePage}>
      <View style={styles.section}>
          <View style={styles.imgCol}>
            <Image style={styles.image} src="https://react-pdf.org/images/logo.png" />
          </View>
          <View style={styles.heading}>
            <Text style={{ fontSize: 25, fontWeight: "bold", }}>20190427 elcentral inligt offert</Text>
            <View style={styles.headerSection}>
              <View style={styles.headerCol}>
                <Text style={{ fontSize: 15, fontWeight: "bold" }}>Customer</Text>
                <Text style={{ fontSize: 13, fontWeight: "normal" }}>20203 Stena Kobbe åäö</Text>
                {/* <Text style={{ fontSize: 13, fontWeight: "normal" }}>0739163396</Text> */}
              </View>
              <View style={styles.headerCol}>
                <Text style={{ fontSize: 15, fontWeight: "bold" }}>Contact person</Text>
                <Text style={{ fontSize: 13, fontWeight: "normal" }}>roswell niklasson</Text>
                {/* <Text style={{ fontSize: 13, fontWeight: "normal" }}>0739183396</Text> */}
              </View>
              <View style={styles.headerCol}>
                <Text style={{ fontSize: 15, fontWeight: "bold" }}>Workplace address</Text>
                <Text style={{ fontSize: 13, fontWeight: "normal" }}>Björkhemsvägen 26</Text>
                {/* <Text style={{ fontSize: 13, fontWeight: "normal" }}>29154 Kristianstad</Text> */}
              </View>
            </View>
          </View>
        </View>
        { }
        <View style={[styles.headerSection, { marginLeft: 15 }]}>
          <View style={styles.headerCol}>
            <Text style={{ fontSize: 15, fontWeight: "bold", }}> {t("articles")}:</Text>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRowHead}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("wholesale")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("article_number")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("article_name")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("unit")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("quantity")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("purchase_price")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("selling_price")}</Text>
            </View>
           
          </View>

          {materielreports?.material?.map((item, index) => (
            <View key={index} style={styles.headerSection}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  <Text>{item.wholesale}</Text>
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  <Text>{item.number}</Text>
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  <Text>{item.name}</Text>
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  <Text>{item.unit}</Text>
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  <Text>{item.quantity}</Text>
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  <Text>{item.purchasePrice}</Text>
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  <Text>{item.sellingPrice}</Text>
                </Text>
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  )
};

export default MaterielPdf;
